import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { YJ_ACCESS_TOKEN, USER_INFO_KEY } from '../configuration'
import { tokenExpire } from './utils';
import {LINE_CHANNEL_ID,LINE_REDIRECT_URI,IS_USER_LOGIN } from '../configuration'

axios.interceptors.response.use((response) => response, function (error) {
    if (typeof error.response?.data === 'object') {
        error.response.data = JSON.stringify(error.response.data);
    }
    return Promise.reject(error);
});
// TODO
// axios.interceptors.response.use(function (response) {
//     //Dispatch any action on success
//     return response;
// }, function (error) {
//     if (error.response.status === 401) {
//         //Add Logic to 
//         //1. Redirect to login page or 
//         //2. Request refresh token
//     }
//     return Promise.reject(error);
// });


// || tokenExpire()
export const getAuthHeader = () => {
    const yjToken = localStorage.getItem(YJ_ACCESS_TOKEN);

    if (!yjToken) { // token 似乎過一小時就會被清掉
        //TODO refresh token 
        
        // localStorage.removeItem(IS_USER_LOGIN);
        // localStorage.removeItem(USER_INFO_KEY);
        // localStorage.removeItem(YJ_ACCESS_TOKEN);
        // localStorage.setItem(IS_USER_LOGIN, JSON.stringify(false));
        localStorage.clear();

        window.location.href = `https://access.line.me/oauth2/v2.1/disable_auto_login=true&authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=STATE&scope=openid%20profile%20email`;
        // window.location.pathname = '/mobile';
    } else {
        // console.log("[getAuthHeader] yjToken :"+yjToken);
        return { headers: { 'Authorization': `Bearer ${yjToken}`, "Content-Type": "application/json" } }
    }

}

export const getLineAuthHeader = (idToken : string) => {

    return { headers: { "Authorization": `Bearer ${idToken}` , "Content-Type": "application/json" } }
    
}

export async function get(url: string, config?: AxiosRequestConfig) {
    // return axios.get(`${url}`, { ...config }).then(response => response);
    return axios.get(`${url}`, { ...config });
}

export async function post(url: string, payload?: object, config?: AxiosRequestConfig) {
    return axios.post(`${url}`, payload, { responseType: 'json', ...config });
}

export async function patch(url: string, payload: object) {
    return axios.patch(`${url}`, payload, { responseType: 'json' }).then(response => response);
}

export async function _delete(url: string) {
    return axios.delete(`${url}`).then(response => response);
}
