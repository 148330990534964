import { post, get, getAuthHeader } from './base';
import { API_URL } from '../configuration';
import { useQuery } from 'react-query';
import {AttractionData} from '../types/CustomType';


//取得預設標籤
const findDefault = async () =>{
    const response = await get(`${API_URL}/api/attraction?query_type=public`);
    return response.data;
}

const findTag = async (tag_id : string) =>{ 
    const response = await get(`${API_URL}/api/attraction?query_type=tag&tag_id=${tag_id}`);
    //console.log(response);
    return response.data;
}


  
const getData = async (tag_id : string): Promise<AttractionData[]> => {
    const response = await get(`${API_URL}/api/attraction?query_type=tag&tag_id=${tag_id}`);
    return await response.data.message;
};


const AttractionService = {
    findDefault,
    findTag,
    getData,
}

export default AttractionService;
