import { post, get, getAuthHeader,getLineAuthHeader } from './base';
import { API_URL } from '../configuration';
import {UserInfoData,AuthInfoData} from '../types/CustomType';
import { useQuery, QueryCache } from 'react-query';

//取得Line授權資訊  
const getYjTokenAndStatus = async (idToken: string): Promise<AuthInfoData> => {
    
    const config = {
        headers: { "Authorization": `Bearer ${idToken}` }
    };
    const response = await get(`${API_URL}/api/login`,config);
   
    return await response.data.message;
};


export const sendQRcode = async (payload: object) => {
    return await post(`${API_URL}/api/myloyaltyCard`, payload, getAuthHeader())
}


const getYjTokenAndStatus2 = async (payload: object) => {
    
    return await post(`${API_URL}/api/login`, payload)
};



//取得個人資訊  
const getUserInfoData = async (): Promise<UserInfoData> => {
    
    const response = await get(`${API_URL}/api/user`, getAuthHeader());
    // console.log(response);
    return await response.data.message;
};






const UserService = {
    getUserInfoData,
    getYjTokenAndStatus,
    getYjTokenAndStatus2
}

export default UserService;
