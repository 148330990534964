import {MapInfoContext} from '../context/mapInfoContext';
import React, { useEffect, useState,useContext } from 'react';

import attraction_maker from '../assets/images/map_marker/attraction_maker.png'
import food_maker from '../assets/images/map_marker/food_maker.png'
import hotel_maker from '../assets/images/map_marker/hotel_maker.png'
import monument_maker from '../assets/images/map_marker/monument_maker.png'
import temple_maker from '../assets/images/map_marker/temple_maker.png'
import {AttractionData} from '../types/CustomType';





export const ProcessCurrentUserData = () => {

  const mapContext = useContext(MapInfoContext);

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {

        mapContext.setInMyLocation(position.coords.latitude,position.coords.longitude);
        
      }
    )
  } else {
    console.log("Geolocation is not supported by this browser.");
  }

}

export const ProcessMapData = (mapContext : any,attractionData: AttractionData[],isDrawPath : boolean) => {

    // console.log("[ProcessMapData]");
    const tmp:{lat: number;lng: number;iconUrl : string;}[] = [];

   
    // mapContext.isDrawPath = isDrawPath;

    if(attractionData == null || attractionData == undefined)
      return;

    // console.log("[mapLib] attractions ="+attractionData.length);
    mapContext.setInAttractions(attractionData);
    mapContext.setInDrawPath(isDrawPath);

    for (let i = 0; i < attractionData.length; i++) {

        const val = attractionData[i];

        switch(val.maker_type){
          case "attraction":
         
              tmp.push({lat: parseFloat(val.latitude), lng: parseFloat(val.longitude),iconUrl:val.icon_slug != null ? val.icon_slug : "https://storage.googleapis.com/yongjing-storge/default/map_icon/attraction.png"})
            break;
          case "temple":
           
              tmp.push({lat:parseFloat(val.latitude), lng: parseFloat(val.longitude),iconUrl:val.icon_slug != null ? val.icon_slug :"https://storage.googleapis.com/yongjing-storge/default/map_icon/temple.png"})
            break;
          case "food":
              tmp.push({lat: parseFloat(val.latitude), lng: parseFloat(val.longitude),iconUrl:val.icon_slug != null ? val.icon_slug :"https://storage.googleapis.com/yongjing-storge/default/map_icon/food.png"})
            break;
          case "monument":
              tmp.push({lat:parseFloat(val.latitude), lng: parseFloat(val.longitude),iconUrl:val.icon_slug != null ? val.icon_slug :"https://storage.googleapis.com/yongjing-storge/default/map_icon/monument.png"})
            break;
          case "hotel":
              tmp.push({lat:parseFloat(val.latitude), lng: parseFloat(val.longitude),iconUrl:val.icon_slug != null ? val.icon_slug :"https://storage.googleapis.com/yongjing-storge/default/map_icon/hotel.png"})
            break;
        }
    }

    mapContext.setInMarkers(tmp);
    mapContext.setInIsUpdate(true);

}

export const ResetMapData = (mapContext : any) => { 
  mapContext.attraction = [];
  mapContext.isDrawPath = false;
  mapContext.markers = [];
}