import React, { useEffect, useState } from 'react';
import { Image, Button } from 'antd';
// import MobileLayout from '../../layouts/MobileLayout';
// import quizIndex from '../assets/images/quiz/quiz_index.png'
import './style/quizStyle.css'
import { AttractionData } from '../types/CustomType';

interface AnswerOptionData {
    option_title: string;
    option_icon: string;
    option_attraction: AttractionData;
}

interface QuizProps {
    nextQusetion: (questionNo: number, userAnswer: number, userAnswerattraction: AttractionData,questionTitle:string,questionAnswer:string) => void;
    question: string;
    answer: AnswerOptionData[];
    questionNo: number;
    userAnswerResult: number[];
}

const QuizLayout3: React.FC<QuizProps> = ({ nextQusetion, question, answer, questionNo, userAnswerResult }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [optionIcon1, setOptionIcon1] = useState<any>();
    const [optionIcon2, setOptionIcon2] = useState<any>();
    const [optionIcon3, setOptionIcon3] = useState<any>();
    const [optionIcon4, setOptionIcon4] = useState<any>();

    useEffect(() => {
        Loading();
    }, [question]);

    async function Loading() {
        setLoading(false);
        Async();
        setLoading(true);
    }

    function Async() {
        return new Promise((res) => {
            console.log("icon = "+answer[0].option_icon);
            // setOptionIcon1(require('../assets/images/quiz/' + answer[0].option_icon + '.png'));
            // setOptionIcon2(require('../assets/images/quiz/' + answer[1].option_icon + '.png'));
            // setOptionIcon3(require('../assets/images/quiz/' + answer[2].option_icon + '.png'));
            // setOptionIcon4(require('../assets/images/quiz/' + answer[3].option_icon + '.png'));
            setOptionIcon1(answer[0].option_icon);
            setOptionIcon2(answer[1].option_icon);
            setOptionIcon3(answer[2].option_icon);
            setOptionIcon4(answer[3].option_icon);
        })
    }



    return (

        <>
            {!loading && <p>載入資料中...</p>}
            {loading && (
                <div className='text-center w-full flex-grow flex flex-col relative bg-sixth'>
                    < div className='flex flex-col justify-center items-center  p-4 flex-1   ' >
                        <div className='flex p-2 sm:p-4 text-xl sm:text-2xl text-black font-extrabold'>
                            旅遊小測驗
                        </div>
                        <div className='flex p-2 sm:p-4 min-h-5 sm:min-h-8 text-lg sm:text-xl text-tertiary text-left overflow-y-auto break-words'>
                            {question}
                        </div>
                    </div >

                    <div className='flex flex-col justify-center items-center text-xl  text-fourth  flex-1  '>
                        <div className='flex flex-col justify-around items-center w-full p-3'>
                            <Button className={`flex w-11/12 h-[75px]  sm:min-h-[105px] p-3 my-1 border-tertiary rounded-2xl items-center hover:border-amber-700 ${userAnswerResult[questionNo] == 1 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 1, answer[0].option_attraction,question,answer[1].option_title) }}>
                                <Image src={optionIcon1} preview={false} className='max-h-[55px] sm:max-h-[80px]' />
                                <div className='px-5 py-4 w-3/4 h-16 break-words overflow-y-auto whitespace-normal text-left text-base md:text-xl  text-sixth font-semibold'>{answer[0].option_title}</div>
                            </Button>
                            <Button className={`flex w-11/12 h-[75px]  sm:min-h-[105px] p-3 my-1 border-tertiary rounded-2xl items-center hover:border-amber-700 ${userAnswerResult[questionNo] == 2 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 2, answer[1].option_attraction,question,answer[1].option_title) }}>
                                <Image src={optionIcon2} preview={false} className='max-h-[55px] sm:max-h-[80px]' />
                                <div className='px-5 py-4  w-3/4 h-16 break-words overflow-y-auto whitespace-normal text-left text-base md:text-xl text-sixth font-semibold'>{answer[1].option_title}</div>
                            </Button>
                            <Button className={`flex w-11/12 h-[75px]  sm:min-h-[105px] p-3 my-1 border-tertiary rounded-2xl items-center hover:border-amber-700 ${userAnswerResult[questionNo] == 3 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 3, answer[2].option_attraction,question,answer[1].option_title) }}>
                                <Image src={optionIcon3} preview={false} className='max-h-[55px] sm:max-h-[80px]' />
                                <div className='px-5 py-4  w-3/4 h-16 break-words overflow-y-auto whitespace-normal text-left text-base md:text-xl text-sixth font-semibold'>{answer[2].option_title}</div>
                            </Button>
                            <Button className={`flex w-11/12 h-[75px]  sm:min-h-[105px] p-3 my-1 border-tertiary rounded-2xl items-center hover:border-amber-700 ${userAnswerResult[questionNo] == 4 ? "border-4" : ""}`} onClick={() => { 
                                    nextQusetion(questionNo, 4, answer[3].option_attraction,question,answer[1].option_title) 
                                    //console.log(answer[3].option_attraction);
                                }}>
                                <Image src={optionIcon4} preview={false} className='max-h-[55px] sm:max-h-[80px]' />
                                <div className='px-5 py-4  w-3/4 h-16 break-words overflow-y-auto whitespace-normal text-left text-base md:text-xl text-sixth font-semibold'>{answer[3].option_title}</div>
                            </Button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};
export default QuizLayout3;
