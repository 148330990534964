import React, { useState, useEffect } from 'react';
import { Image, Button, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';

import { useQuery } from 'react-query';
// import ItineraryDetailType from '../types/ItineraryType';
import ItineraryService from '../apis/ItineraryService';
// import { AttractionType } from '../types/ItineraryType';
import {AttractionData,ItineraryDetailType} from '../types/CustomType';

interface ItineraryCollapseProps {
    showDrawer: (title: string | null, view: string,data:AttractionData[] | null) => void;
    openCollapse: (title: string, view: string) => void;
    itinerary_: ItineraryDetailType | undefined;
}

const ItineraryCollapse: React.FC<ItineraryCollapseProps> = ({ showDrawer: showDrawer, openCollapse: openCollapse,itinerary_:  itinerary}) => {


    

    const handlerMoreTags = () => {
        showDrawer('所有分類', 'allTagsDrawer', null)
    }

    const handlerOtherItinerary = () => {
        showDrawer('所有經典路線', 'itinerary', null)
    }

    const handlerAllTags = (tagName: string) => {
        openCollapse(`關鍵字瀏覽: #${tagName}`, 'allTagsCollapse')
    }

    const handlerAttractionsDrawer = (drawerTitleName: string| undefined, view: string,data:AttractionData[] | undefined) => {
       //console.log("data = "+data?.length);
        showDrawer(drawerTitleName ?? "", view,data ?? null);
    }

    useEffect(() => {
        // handlerAttractionsDrawer(itinerary?.title, 'attractions_path',itinerary?.way_attractions)
    }, []);

    return (
        <div>
            {/* 1 */}
            <div className='flex flex-col py-2'>
                <div className=' flex justify-cneter items-center h-[80px]'>
                    <div className='  w-3/5 flex justify-center items-center h-full bg-black'>
                        {/* <div className='px-1'> */}
                        <Image
                            width={320}
                            height={80}
                            className='object-cover '
                            src={itinerary?.image_slug}
                        />
                        {/* </div> */}
                    </div>
                    <div className=' flex flex-col w-4/5 justify-start items-start px-2 h-full'>
                        <div className=' text-primary text-xs font-extrabold'>行程時間：
                            <span className=' font-normal'>{itinerary?.time_mode}</span>
                        </div>
                        <div className=' text-primary text-xs py-1 font-extrabold'>旅遊模式：
                            <span className=' font-normal'>{itinerary?.travel_mode}</span>
                        </div>
                        <div className='text-primary text-xs font-extrabold pt-1 flex justify-start items-end w-full'>
                            <button className=' text-primary border-primary border rounded-lg bg-white h-6' onClick={() => { handlerAttractionsDrawer(itinerary?.title, 'attractions_path',itinerary?.way_attractions) }}>
                                <FontAwesomeIcon icon={faRectangleList} />
                                景點清單
                            </button>
                        </div>
                    </div>
                </div>
                <div className=' text-secondary h-[70px] w-full overflow-auto my-2 text-left'>{itinerary?.description}</div>
                <div className='flex flex-wrap justify-start w-full py-2 px-1 border-b-2 border-secondary'>
                    {
                        itinerary?.tags.map((value,index)  => (   
                            <Button
                                key={index}
                                shape="round"
                                className=' text-white bg-secondary m-1 hover:bg-primary hover:text-white text-xs'
                            >
                                #{value.name}
                            </Button>
                        ))
                    }
                   
                </div>

                <div className='flex justify-between items-center text-primary text-base py-2'>
                    <div onClick={handlerMoreTags}>更多分類</div>
                    <div onClick={handlerOtherItinerary}>其他經典路線</div>
                </div>
            </div>
        </div>
    );
};

export default ItineraryCollapse;
