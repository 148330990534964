import React from 'react';
import { Layout, Space } from 'antd';
const { Footer, Content } = Layout;
import Header from './Header';
import './styles.css';

interface MainLayoutProps {
  children: React.ReactNode;
}

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout className='mainBlock layout w-screen justify-center items-center '>
        <Header />
        {/* <Header className=' bg-slate-400 w-full flex justify-end items-center'>
          <Dropdown menu={{ items }} placement="bottomLeft" arrow>
            <Button shape="circle" type="text" icon={<SettingOutlined />} />
          </Dropdown>
        </Header> */}
        <Content className=' text-center h-full w-full bg-white'>
          {/* <div className="bg-white w-full h-full rounded shadow">
            <h1 className="text-2xl font-bold mb-4"> Yongjing Home Page</h1>
          </div> */}
          {children}
        </Content>
        <Footer className=' bg-slate-400 bottom-0 w-full text-white'>© {new Date().getFullYear()} Copyright 彰化縣永靖鄉共好協會 </Footer>
      </Layout>
    </Space >
  )


};

export default MainLayout;
