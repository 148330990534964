import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import CallbackPage from './pages/CallbackPage';
import ProfilePage from './pages/ProfilePage';
import MobilePage from './pages/MobilePage';
import MobileProfilePage from './pages/MobileProfilePage';
import CollectPointCards from './components/MobileProfile/CollectPointCards'
import CollectPointCardDetail from './components/MobileProfile/CollectPointCardDetail'

import MobileProfile from './components/MobileProfile/MemberProfile'
import ExchangeCoupon from './components/MobileProfile/ExchangeCoupon'
import QuizPage from './pages/QuizPage';
import QuizStepPage from './pages/QuizStepPage';
import { AuthContextProvider } from './context/authContext'
import { MapContextProvider } from './context/mapInfoContext'
import { UserContextProvider } from './context/userInfoContext';
import { ConfigProvider } from "antd";
import zhTW from "antd/lib/locale/zh_TW";

import ScanLiffCodeComponent  from './components/ScanLiffCodeComponent';


const Router = () => {

 
  return (
    <>
      <ConfigProvider locale={zhTW}>
        <AuthContextProvider>
          <UserContextProvider >
            <MapContextProvider >
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<HomePage />} />
                  <Route path='/mobile/login' element={<LoginPage />} />
                  <Route path='/mobile/callback/*' element={<CallbackPage />} />
                  <Route path='/mobile/profile/edit' element={<ProfilePage />} />
                  <Route path='/profile/register' element={<ProfilePage />} />
                  <Route path='/mobile/attraction/:attractionName' element={<MobilePage />} />
                  <Route path='/mobile/itinerary/:itineraryUUID/:itineraryName' element={<MobilePage />} />
                  <Route path='/mobile/profile' element={<MobileProfilePage />}>
                    <Route index element={<Navigate to="/mobile/profile/main" replace />} />
                    <Route path='/mobile/profile/main' element={<MobileProfile />} />
                    <Route path='/mobile/profile/collection' element={<CollectPointCards />} />
                    <Route path='/mobile/profile/collection/:id/:qrcode' element={<CollectPointCardDetail />} />
                    {/* <Route path='/mobile/profile/coupon' element={<ExchangeCoupon />} /> */}
                  </Route>
                  <Route path='/mobile/quiz' element={<QuizPage />} />
                  <Route path='/mobile/quiz/step' element={<QuizStepPage />} />
                  <Route path='/mobile/scan' element={<ScanLiffCodeComponent/>} />
                  <Route path='*' element={<MobilePage />} />                  
                </Routes>
              </BrowserRouter>
            </MapContextProvider >
          </UserContextProvider>
        </AuthContextProvider>
      </ConfigProvider>
    </>
  )
}

export default Router;
