import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import ItineraryType from '../types/ItineraryType';
import Tag from '../types/Tag';
import { useQuery } from 'react-query';
import ItineraryService from '../apis/ItineraryService';

interface ItineraryProps {
    onClose: () => void;
    openCollapse: (title: string, view: string, uuid:string) => void;
}

const Itinerary: React.FC<ItineraryProps> = ({ onClose, openCollapse }) => {


    const [itineraries, setItineraries] = useState<ItineraryType[]>([]);
    //const [tags, setTas] = useState<Tag[]>([]);


    const { isLoading,isSuccess,isError,data} = useQuery<any, Error>(
        ["query-default-itineraries"],
        async () => {
          return await ItineraryService.findDefault();
        },
        {
          keepPreviousData: true,
          onSuccess: (res) => {
            setItineraries(res.message);
          },
          onError: (err: any) => {
            console.log(err);
          }
        }
    );

    const handlerLinkToDetail = ((title: string,uuid:string) => {
        onClose();
        openCollapse(title, 'itineraryCollapse',uuid);
    })


    return (
        <div>
           
            {itineraries.map((itinerary, index)  => ( 
                <div key={index}>
                    <div className='flex flex-col py-2'>
                    <div className=' flex justify-between'>
                        <div className=' text-secondary text-xl py-2 w-full font-bold'>{itinerary.title}</div>
                        <div className=' flex justify-center items-center'>
                            <div className=' text-white bg-primary text-xs w-[48px] h-[18px] rounded-md px-1' onClick={() => handlerLinkToDetail(itinerary.title,itinerary.uuid)}>
                                {`詳 情 >>`}
                            </div>
                        </div>
                    </div>

                    <div className=' h-[130px] w-[313px] flex py-4'>
                        <div className='px-1'>
                            <Image
                                width={300}
                                height={127}
                                className='object-cover'
                                src={itinerary.image_slug}
                            />
                        </div>
                    </div>
                    <div className=' text-secondary h-[108px] w-full overflow-auto'>{itinerary.description}</div>
                    <div className=' flex py-4'>
                        <div className=' w-3/4  border-secondary'>
                            <div className=' text-primary text-xs pd-1 font-extrabold'>行程時間：
                                <span className=' font-normal'>{itinerary.time_mode}</span>
                            </div>
                            <div className=' text-primary text-xs pd-1 font-extrabold'>旅遊模式：
                                <span className=' font-normal'>{itinerary.travel_mode}</span>
                            </div>
                        </div>
                    </div>

                    <div className='w-full  text-tertiary h-[108px] overflow-auto'>
                        {
                            itinerary.tags.map((value,index)  => (  
                                <span className='px-1' key={index}>#{value.name}</span>
                            ))
                        }
                    </div>
                </div>
                </div>
            ))}  
            
            
           
        </div>
    );
};

export default Itinerary;
