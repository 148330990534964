// https://bitsbydenis.medium.com/react-google-maps-marker-clustering-34219f22fed8

import React, { useCallback, useEffect, useRef, useState,forwardRef, useImperativeHandle,useContext} from 'react';
import { GoogleMap, MarkerF, OverlayViewF, OverlayView, useJsApiLoader, Polygon, Rectangle, Marker} from '@react-google-maps/api';
import Supercluster, { ClusterFeature, PointFeature } from 'supercluster';
import { AttractionData } from '../types/CustomType';
import { Image, Button, Drawer } from 'antd';
import AttractionsSingle from './AttractionsSingle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import {MapInfoContext} from '../context/mapInfoContext';
// import mapStyles from './mapStyles.json';

const containerStyle = { width: '100%', height: '100%' };
const center = { lat: 23.92116545048729, lng: 120.54020492997185};

const MAX_ZOOM_LEVEL = 13;


const gobalData = [
    { lat: -85.1054596961173, lng: -180 },
    { lat: 85.1054596961173, lng: -180 },
    { lat: 85.1054596961173, lng: 180 },
    { lat: -85.1054596961173, lng: 180 },
    { lat: -85.1054596961173, lng: 0 },
];


const yjKmlData = [{ lat: 23.9303535, lng: 120.5374092 },
{ lat: 23.9308136, lng: 120.5365036 },
{ lat: 23.9295976, lng: 120.5366752 },
{ lat: 23.9302645, lng: 120.5350015 },
{ lat: 23.9317551, lng: 120.5340574 },
{ lat: 23.9366974, lng: 120.5325124 },
{ lat: 23.936619, lng: 120.5315683 },
{ lat: 23.9366974, lng: 120.5305383 },
{ lat: 23.9377173, lng: 120.5282209 },
{ lat: 23.9377957, lng: 120.5260751 },
{ lat: 23.9368543, lng: 120.5255602 },
{ lat: 23.9365013, lng: 120.5210111 },
{ lat: 23.9377565, lng: 120.5189083 },
{ lat: 23.9359914, lng: 120.5170629 },
{ lat: 23.9320689, lng: 120.5196378 },
{ lat: 23.92897, lng: 120.5207107 },
{ lat: 23.9315982, lng: 120.5159471 },
{ lat: 23.9306567, lng: 120.5101535 },
{ lat: 23.9327749, lng: 120.5086086 },
{ lat: 23.934187, lng: 120.5091236 },
{ lat: 23.9356384, lng: 120.507879 },
{ lat: 23.9324094, lng: 120.5032055 },
{ lat: 23.9330762, lng: 120.5017893 },
{ lat: 23.9323309, lng: 120.5011885 },
{ lat: 23.9308011, lng: 120.5015318 },
{ lat: 23.9291144, lng: 120.5007164 },
{ lat: 23.9304481, lng: 120.5007164 },
{ lat: 23.9306834, lng: 120.4993431 },
{ lat: 23.9306834, lng: 120.4971115 },
{ lat: 23.9296635, lng: 120.4969399 },
{ lat: 23.9298989, lng: 120.4955666 },
{ lat: 23.9271158, lng: 120.4949178 },
{ lat: 23.9275866, lng: 120.4933729 },
{ lat: 23.9256252, lng: 120.4939737 },
{ lat: 23.9235069, lng: 120.5002393 },
{ lat: 23.9235853, lng: 120.5015268 },
{ lat: 23.9209962, lng: 120.5029859 },
{ lat: 23.9191132, lng: 120.5052175 },
{ lat: 23.9176397, lng: 120.5096621 },
{ lat: 23.9156036, lng: 120.5104583 },
{ lat: 23.9137205, lng: 120.5150932 },
{ lat: 23.9130928, lng: 120.5301994 },
{ lat: 23.908228, lng: 120.5365509 },
{ lat: 23.9011658, lng: 120.5374092 },
{ lat: 23.9024214, lng: 120.5478805 },
{ lat: 23.9061878, lng: 120.5466789 },
{ lat: 23.9069725, lng: 120.5533737 },
{ lat: 23.9005381, lng: 120.554747 },
{ lat: 23.905717, lng: 120.5701965 },
{ lat: 23.9135636, lng: 120.5650466 },
{ lat: 23.9159174, lng: 120.5762046 },
{ lat: 23.9215665, lng: 120.575003 },
{ lat: 23.9221942, lng: 120.5772346 },
{ lat: 23.9267446, lng: 120.5724281 },
{ lat: 23.9331778, lng: 120.5756897 },
{ lat: 23.9356882, lng: 120.562815 },
{ lat: 23.9367865, lng: 120.5607551 },
{ lat: 23.9386692, lng: 120.5597251 },
{ lat: 23.9341192, lng: 120.5509704 },
{ lat: 23.9349037, lng: 120.5432457 },
{ lat: 23.9323933, lng: 120.544104 },
{ lat: 23.9303535, lng: 120.5374092 }];
const options = {
    // streetViewControl: false,
    // mapTypeControl: false,
    // fullscreenControl: false,
    // styles: mapStyles,
    maxZoom: 13,
    minZoom: 20
};



type Map = google.maps.Map & { zoom: number };

function formatDataToGeoJsonPoints(data: AttractionData[], isDrawOrder: boolean): GeoJSON.Feature<GeoJSON.Point>[] {

    return data.map((attraction) => ({

        type: 'Feature',
        geometry: { type: 'Point', coordinates: [Number(attraction.longitude), Number(attraction.latitude)] },
        properties: { cluster: false, ...attraction, attraction: attraction, isDrawOrder: isDrawOrder }
    }));
}

function getLabel(pointCount: number): google.maps.MarkerLabel {
    return { text: pointCount.toString(), color: '#353535', fontWeight: 'bold' };
}



export interface MapComponentDataProps {
    attractions: AttractionData[];
    isDrawOrder: boolean //是否要畫順序編號
    triggerCurrent : boolean;
    setMyLocation: (isMyLocation: boolean) => void;
    singleName : string | undefined;
    triggerSingle : boolean;
}


const MapComponent_v2: React.FC<MapComponentDataProps> = ({ attractions, isDrawOrder,triggerCurrent,setMyLocation,triggerSingle,singleName}) => {

    

    const mapContext = useContext(MapInfoContext);

    const { isLoaded: isMapLoaded } = useJsApiLoader({ googleMapsApiKey: "AIzaSyAP0Ij24_Z323jesR6lBlXKowmHjJKoXcQ", language: "zh-TW" });
    const [zoom, setZoom] = useState<number>(options.maxZoom);
    const [bounds, setBounds] = useState<GeoJSON.BBox>([0, 0, 0, 0]);
    const [clusters, setClusters] = useState<ClusterFeature<any>[]>();
    const mapRef = useRef<Map>();
    const [allowGestures, setAllowGestures] = useState<boolean>(true); //手勢控制
    //當前位置
    const [currentPosition, setCurrentPosition] = useState<{lat: number, lng: number} | null>(null);
    const [currentMarker, setcurrentMarker] = useState<google.maps.Marker | null>(null);
    const [currentQueryMarker, setcurrentQueryMarker] = useState<AttractionData>();

    




    const [check, setCheck] = useState<boolean>(false);
    // 地圖 Marker 聚集有關
    // const [radiusCluster, setRadiusCluster] = useState<number>(1000);
    const [markerCluster, setMarkerCluster] = useState<Supercluster>(new Supercluster({ radius: 1000, maxZoom: options.maxZoom }));
    // zoom 改變
   
    
    // const sc = new Supercluster({ radius: 1000, maxZoom: options.maxZoom });

    

    const [currentPolygon, setcurrentPolygon] = useState<google.maps.Polygon | null>(null);


    useEffect(() => {
       
        if (attractions?.length && mapRef.current) {
            
            if(!triggerSingle) {

                const bounds_ = new google.maps.LatLngBounds();

                for (let i = 0; i < attractions.length; i++) {
                        bounds_.extend({ lat: Number(attractions[i].latitude), lng: Number(attractions[i].longitude) });
                        
                        mapRef.current.fitBounds(bounds_);
                }

                if(currentPolygon){
                    currentPolygon.setMap(null);
                }

                const specificAreaPolygon = new google.maps.Polygon({
                    paths: [gobalData, yjKmlData],
                    strokeColor: '#fff',
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillColor: '#000',
                    fillOpacity: 0.3,
                    map: mapRef.current,
                });
                setcurrentPolygon(specificAreaPolygon)

            } 
            
            
        }
    }, [attractions]);

    useEffect(() => {

        // console.log("[MapComponent_v2] attractions, bounds,markerCluster")

        if (attractions?.length && mapRef.current) {

            if(!triggerSingle) {

                if(!currentPolygon){
                    // currentPolygon.setMap(null);
                    // console.log("沒 Polygon");
                    const specificAreaPolygon = new google.maps.Polygon({
                        paths: [gobalData, yjKmlData],
                        strokeColor: '#fff',
                        strokeOpacity: 0,
                        strokeWeight: 0,
                        fillColor: '#000',
                        fillOpacity: 0.3,
                        map: mapRef.current,
                    });
                    
                    setcurrentPolygon(specificAreaPolygon)
                } 
    
                
               
                if (!check) {
                    // console.log("被觸發");
                    const bounds_ = new google.maps.LatLngBounds();
                    for (let i = 0; i < attractions.length; i++) {
                            bounds_.extend({ lat: Number(attractions[i].latitude), lng: Number(attractions[i].longitude) });
                            mapRef.current.fitBounds(bounds_);
                    }
                    setCheck(true);
                }
                markerCluster.load(formatDataToGeoJsonPoints(attractions, isDrawOrder) as PointFeature<GeoJSON.Feature<GeoJSON.Point>>[]);
                setClusters(markerCluster.getClusters(bounds, zoom));
            } else {

                
                 setZoom(17);
            }

            

        }
    }, [attractions, bounds,markerCluster]);


    useEffect(() => {
        if(triggerCurrent){
           
            getUserLocation();
        
        } else {
            
            setCurrentPosition({
                lat: center.lat,
                lng: center.lng
            });
            currentMarker?.setMap(null);
        }
        
    }, [triggerCurrent]);


    useEffect(() => {

        // console.log("MapCompont_v2 : 開始變化 singleName:"+singleName+" triggerSingle:"+triggerSingle);

        if(triggerSingle){
           
            for (let i = 0; i < attractions.length; i++) {
                if(attractions[i].title == singleName) {
                    setCurrentPosition({
                        lat: Number(attractions[i].latitude),
                        lng: Number(attractions[i].longitude)
                    });
                    setcurrentQueryMarker(attractions[i]);

                //   setZoom(17);
                    
                }
            }

           

        }
        
    }, [triggerSingle,attractions]);


    if (!isMapLoaded) return null;

    function handleClusterClick({ id, lat, lng }: { id: number, lat: number, lng: number }) {
       
        const expansionZoom = Math.min(markerCluster.getClusterExpansionZoom(id), 20);
        mapRef.current?.setZoom(expansionZoom);
        mapRef.current?.panTo({ lat, lng });
    }

    

    const getUserLocation = () => {
        navigator.geolocation.getCurrentPosition(position => {
            setCurrentPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });


            const currentMarkerInstance = new google.maps.Marker({
                position: { lat: position.coords.latitude, lng: position.coords.longitude },
                map: mapRef.current,
                icon: {
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 10,
                  fillOpacity: 1,
                  strokeWeight: 2,
                  fillColor: '#5384ED',
                  strokeColor: '#ffffff',
                },
            });

            setcurrentMarker(currentMarkerInstance)

          

        }, error => {
            console.error("Error retrieving user's location:", error);
        });
    };

  
    function handleBoundsChanged() {
        if (mapRef.current) {
            const bounds = mapRef.current.getBounds()?.toJSON();
            setBounds([bounds?.west || 0, bounds?.south || 0, bounds?.east || 0, bounds?.north || 0]);
        }
    }

  

    function handleZoomChanged() {
        if (mapRef.current ) {

            let localZoom = 0;
             
            if(mapRef.current?.zoom > 13) {
               
                localZoom = 17;
            }

            if(mapRef.current?.zoom <= 13) {
               
                localZoom = 13;
            }

           

            if (mapRef.current?.zoom <= 12) {
                mapRef.current.setOptions({ minZoom: 12 });
            }
            // console.log("改變之後地圖狀態zoom值:"+mapRef.current?.zoom);

            if(!triggerSingle){
                setZoom(mapRef.current?.zoom);
                mapContext.setMapZoom(mapRef.current?.zoom);
            }

           
            

            if(localZoom <= 13){
                
                setMarkerCluster(new Supercluster({ radius: 1000, maxZoom: localZoom}))
            }

            else if(localZoom >= 14 ){
                
                setMarkerCluster(new Supercluster({ radius: 60, maxZoom: localZoom}))
            }

           
        }

      
    }

    function handleMapLoad(map: google.maps.Map) {
        mapRef.current = map as Map;

        // Directly set gestureHandling on the google.maps.Map instance
        // map.setOptions({
        //     gestureHandling: allowGestures ? "greedy" : "none",
        //     draggable:allowGestures,
        //     scrollwheel: false
        // });

        // 添加滚轮事件监听器
        // map.addListener('mousewheel', (event) => {
        //     console.log(event);
        //     if (event.deltaY > 0) {
        //         console.log("滚动向下");
        //     } else {
        //         console.log("滚动向上");
        //     }
        // });


    }

   

    return (
        <>
       
            <GoogleMap
            onLoad={handleMapLoad}
            onBoundsChanged={handleBoundsChanged}
            onZoomChanged={handleZoomChanged}
            mapContainerStyle={containerStyle}
            options={{
                mapTypeControl: false,
                fullscreenControl: false,
                //  scrollwheel: allowGestures,
                gestureHandling: allowGestures ? "greedy" : "none",
                mapId:"811459dd13aa7bc"  //811459dd13aa7bc(正式的) //27322436ed44a21(開發的)
            }
            }
            center={currentPosition || center}
            zoom={zoom}
        >

         
            {
                ( triggerSingle == false) ? 

                <>
                     {clusters?.map(({ id, geometry, properties }) => {
                        const [lng, lat] = geometry.coordinates;
                        const { cluster, point_count, title, available, marker_type, attraction, isDrawOrder,icon_slug } = properties;
                        // count = count + 1;
                        return cluster
                            ? <MarkerF
                                key={`cluster-${id}`}
                                onClick={() => handleClusterClick({ id: id as number, lat, lng })}
                                position={{ lat, lng }}
                                icon="/images/cluster-pin.png"
                                label={getLabel(point_count)} />
                            :
                            <>
                                
                                <AttractionMarker
                                    key={`attraction-${properties.id}`}
                                    position={{ lat, lng }}
                                    title={attraction?.title}
                                    available={available}
                                    icon={ icon_slug !=null ? icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${marker_type}.png`}
                                    zoom={mapRef?.current?.zoom}
                                    attraction={attraction}
                                    isDrawOrder={isDrawOrder}
                                    orderID={`${attraction?.order}`}
                                />
                            </>
                            
                    })}
                </> :
                <>
                   {
                     currentQueryMarker && 
                        <AttractionMarker
                            key={`attraction-${currentQueryMarker?.uuid}`}
                            position={{ lat: Number(currentQueryMarker.latitude), lng: Number(currentQueryMarker.longitude) }}
                            title={currentQueryMarker?.title}
                            available={true}
                            icon={ currentQueryMarker?.icon_slug !=null ? currentQueryMarker?.icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${currentQueryMarker?.maker_type}.png`}
                            zoom={mapRef?.current?.zoom}
                            attraction={currentQueryMarker}
                            isDrawOrder={false}
                            orderID={`1`}
                        />
                   }
                </>
            }

           

        </GoogleMap>
            
        </>
        
    )
}


interface AttractionMarker {
    available: boolean;
    position: google.maps.LatLng | google.maps.LatLngLiteral;
    title: string;
    icon: string;
    attraction: AttractionData;
    isDrawOrder: false;
    orderID: string;
    zoom : any;
}



function getPixelPositionOffset(width: number, height: number): { x: number, y: number } {
    return { x: -(width / 2), y: -(height / 2) };
}



function AttractionMarker({ position, available, title, icon, attraction, isDrawOrder, orderID,zoom }: AttractionMarker) {
    // const [visible, setVisible] = useState(false);
    // const buttonClass = available ? 'success' : 'warning';
    // const buttonText = available ? 'Book' : 'Reserved';

    // const imgUrl = + icon;
    const imgUrl = attraction.icon_slug !=null ? attraction.icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${attraction.maker_type}.png`
    // icon_slug !=null ? icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${marker_type}.png`
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState('');

    const showDrawer = (title: string) => {
        setDrawerTitle(title);
        openDrawer ? "" : setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    return <OverlayViewF
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}>
        {

            <Drawer title={title} placement="right" onClose={onCloseDrawer} open={openDrawer} >
                {
                    attraction && <AttractionsSingle attraction={attraction} />
                }
            </Drawer>
        }
       
        <div className=' relative'>

           {(zoom > 16) ?
           
           <div
                className=' absolute top-[-35px] left-[-50px] z-0 flex w-full'
                onClick={() => showDrawer(`${attraction.title}`)}
            >
                <div className=' px-2 py-1 mx-6 h-8 whitespace-nowrap font-semibold text-sm bg-white  text-primary rounded-full shadow-md flex items-center relative' >
                    <img src={imgUrl}
                        className='px-2 absolute left-[-40px] max-h-full max-w-full'
                        onClick={() => showDrawer(`${attraction.title}`)}
                    />
                    <div className='px-2'>{title}</div>
                    {isDrawOrder &&
                        <span className=' text-xl rounded-full w-6 h-6 text-center bg-fourth text-sixth  '>
                            {orderID}
                        </span>
                    }
                </div>
            </div>
           
           :
           
           <div
                className=' absolute z-0 flex w-full'
                onClick={() => showDrawer(`${attraction.title}`)}
            >
                <div className=' px-2 py-1 mx-6 h-8 whitespace-nowrap font-semibold text-sm   text-primary rounded-full shadow-md flex items-center relative' >
                    <img src={imgUrl}
                        className='px-2 absolute left-[-40px] max-h-full max-w-full'
                        onClick={() => showDrawer(`${attraction.title}`)}
                    />
                    
                    {
                        (isDrawOrder && zoom >=16) &&
                        <span className=' text-xl rounded-full w-6 h-6 text-center bg-fourth text-sixth  '>
                            {orderID}
                        </span>
                    }
                </div>
            </div>
           }
            
        </div>
    </OverlayViewF>
}


function AttractionSingleMarker({ position, title, attraction, isDrawOrder, orderID,zoom }: AttractionMarker) {
    // const [visible, setVisible] = useState(false);
    // const buttonClass = available ? 'success' : 'warning';
    // const buttonText = available ? 'Book' : 'Reserved';

    // const imgUrl = + icon;
    const imgUrl = attraction.icon_slug !=null ? attraction.icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${attraction.maker_type}.png`
    // icon_slug !=null ? icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${marker_type}.png`
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState('');

    const showDrawer = (title: string) => {
        setDrawerTitle(title);
        openDrawer ? "" : setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    return <OverlayViewF
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}>
        {

            <Drawer title={title} placement="right" onClose={onCloseDrawer} open={openDrawer} >
                {
                    attraction && <AttractionsSingle attraction={attraction} />
                }
            </Drawer>
        }
       
        <div className=' relative'>

           
           
           <div
                className=' absolute top-[-35px] left-[-50px] z-0 flex w-full'
                onClick={() => showDrawer(`${attraction.title}`)}
            >
                <div className=' px-2 py-1 mx-6 h-8 whitespace-nowrap font-semibold text-sm bg-white  text-primary rounded-full shadow-md flex items-center relative' >
                    <img src={imgUrl}
                        className='px-2 absolute left-[-40px] max-h-full max-w-full'
                        onClick={() => showDrawer(`${attraction.title}`)}
                    />
                    <div className='px-2'>{title}</div>
                    {isDrawOrder &&
                        <span className=' text-xl rounded-full w-6 h-6 text-center bg-fourth text-sixth  '>
                            {orderID}
                        </span>
                    }
                </div>
            </div>
           
           
          
            
        </div>
    </OverlayViewF>
}

export default MapComponent_v2