import React, { useEffect, useContext } from 'react';
import AuthContext from '../../context/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserToken, getUserProfile } from '../../apis/line'
import { LoadingOutlined } from '@ant-design/icons';
import MainLayout from '../../layouts/ManLayout';
import { getYjTokenAndStatus } from '../../apis/member';
import jwt_decode from 'jwt-decode';
import { API_URL,LINE_LOGIN_URI, LINE_LOGIN_CLIENT_ID, LINE_REDIRECT_URL, IS_MOBILE_LOGIN,LINE_CHANNEL_ID,LINE_REDIRECT_URI,COOKIE_NAME,LINE_CHANNEL_SECRET,COOKIE_GET_LINETOKEN_NAME,COOKIE_LOGUT_NAME} from '../../configuration'
import { hover } from '@testing-library/user-event/dist/hover';
import { ILoginInfo } from '../../types/Member';


import { Button, Dropdown, Layout, Avatar, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

import { USER_INFO_KEY } from '../../configuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLine } from '@fortawesome/free-brands-svg-icons'
import { faQrcode, faHomeLgAlt,faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import { useQuery, QueryCache } from 'react-query';
import { AuthInfoData } from '../../types/CustomType';

import UserService from '../../apis/UserService';
import { YJ_ACCESS_TOKEN,ORIGINAL_URL} from '../../configuration'


interface TokenPayload {
  sub: string;  //TODO
  name: string;
  picture: string;
}



const CallbackPage = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);

  // useEffect(() => {

  //   // console.log("[CheckLoginPage] [lineToken] :"+authCtx.lineToken);

  //   if (authCtx.lineToken!='') {
  //     // console.log("[CheckLoginPage] profile.lineIDToken = "+authCtx.lineToken);
  //     getYjTokenAndStatusAction();
  //   }

  // }, [authCtx.lineToken]);

  // const getProfile = async (code: string) => {
  //   const lineToken = await getUserToken(code).then(res => {
  //     return res?.data
  //   })

  //   console.log("getProfile-token:", lineToken.access_token);
  //   const lineId = await getUserProfile(lineToken.access_token).then(res => {
  //     return res?.data.userId
  //   })

  //   const profile = {
  //     "lineId": lineId,
  //     "lineIDToken": lineToken.id_token
  //   }

  //   return profile;
  // }

  // const login = async (code: string) => {
  //   try {
  //     const profile = await getProfile(`${code}`);
  //     const loginInfo = await getYjTokenAndStatus(profile.lineId, profile.lineIDToken).then((res: any) => {
  //       // console.log('getYjTokenAndStatus: ', res.data.message.token);
  //       return { 'token': res.data.message.token, 'isNewUser': res.data.message.is_new_user };
  //     }).catch((error) => { console.log('getYjTokenAndStatus-err:', error) });
     
  //     if (loginInfo?.token) {
  //       const payload = jwt_decode(loginInfo.token) as TokenPayload;
  //       localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
  //       if (loginInfo.isNewUser) {
  //         // new user
  //         const userInfo = {
  //           userId: payload.sub, //TODO
  //           displayName: payload.name,
  //           profilePicture: payload.picture,
  //           isLogin: false
  //         };
  //         localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
  //         navigate('/profile/register');
  //       } else {
  //         //member
  //         authCtx.updateUserInfo();
  //         authCtx.isMobileLogin ? navigate('/mobile') : navigate('/');
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  const extractLineToken = () => {
    // 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      // 发送请求以获取 LINE Token
      fetch('https://api.line.me/oauth2/v2.1/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code,
          redirect_uri: LINE_REDIRECT_URI,
          client_id: LINE_CHANNEL_ID,
          client_secret: LINE_CHANNEL_SECRET, // 请替换为实际的 Channel Secret
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // const accessToken = data.access_token;

          authCtx.setMobileLogin(false);
          

          // const userId = data.scope;
          // const displayName = data.displayName;
          // const userEmail = data.email; // 获取邮箱地址
          // console.log('User ID:', userId);
          // console.log('Display Name:', displayName);
          // console.log('User Email:', userEmail);

          // const token = data.access_token;
          const idToken = data.id_token;
          // console.log('token:', token);
          // console.log('idToken:', idToken);

          // 在 Cookie 中存储登录状态
         
          
          // setLineToken(idToken)
          // 更新登录状态
          // setLoggedIn(true);
          
          // authCtx.setLineToken(idToken);

          // alert("Line授權 : "+idToken);

          if(idToken!=undefined && idToken) {
            getYjTokenAndStatusAction2(idToken);
          } else {
            localStorage.clear();
            alert("Line授權失敗 : 沒有 token");
            // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
          }
          
        })
        .catch((error) => {
          localStorage.clear();
          console.error('Error fetching LINE Token:', error);
          alert("Line授權失敗"+error);
          // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
        });
    }
  };


  


  const getYjTokenAndStatusAction2 = async (lineToken : string)  => {

    try {
        
      // alert("準備授權 : "+lineToken);

      const updatePayload = {
        "token": `Bearer ${lineToken}`, //QRCode編號
      };

      await UserService.getYjTokenAndStatus2(updatePayload)
      .then((response) => {
          const data = response?.data.message;
          if(data!=undefined && data) {
            // console.log(data.token);
            const loginInfo = { 'token': data.token, 'isNewUser': data.is_new_user };
            localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
            authCtx.setMobileLogin(true);
            // authCtx.updateUserInfo();
            // console.log("[CheckLoginPage] getYjTokenAndStatusAction :"+localStorage.getItem(ORIGINAL_URL));
            if(localStorage.getItem(ORIGINAL_URL)!=null && localStorage.getItem(ORIGINAL_URL))
              navigate(`${localStorage.getItem(ORIGINAL_URL)}`);
            else
              navigate(`/mobile`);
          }
          
      })
      .catch((error) => {
          console.log("[qrcode] error response: ", error);
          const data = error?.message;
          localStorage.clear();
          alert("伺服器授權失敗，重新自動登入");
          // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
      });



      // await UserService.getYjTokenAndStatus(lineToken)
      //   .then((response) => {

      //     if(response){
      //       const loginInfo = { 'token': response.token, 'isNewUser': response.is_new_user };
            
      //       if (loginInfo?.token) {
      //         const payload = jwt_decode(loginInfo.token) as TokenPayload;
      //         localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
      //         authCtx.setMobileLogin(true);
      //         // authCtx.updateUserInfo();
      //         console.log("[CheckLoginPage] getYjTokenAndStatusAction :"+localStorage.getItem(ORIGINAL_URL));
      //         if(localStorage.getItem(ORIGINAL_URL)!=null && localStorage.getItem(ORIGINAL_URL))
      //           navigate(`${localStorage.getItem(ORIGINAL_URL)}`);
      //         else
      //           navigate(`/mobile`);
      //       }
      //     }
       
          
            
      //   })
      //   .catch((error : any) => {
      //     console.log("error ="+error);
      //     localStorage.clear();
      //     alert("伺服器授權失敗 1"+error);
      //     console.error('Error fetching LINE Token:', error);
      //   });

    }
    catch (error) {
        alert("伺服器授權失敗 2"+error);
        // console.log('post qr code error:', error);
        localStorage.clear();
        console.error('Error fetching LINE Token:', error);
        // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
    }
  }




  // const { refetch: getYjTokenAndStatusAction } = useQuery<AuthInfoData, Error>(
  //   `queryKey${authCtx.lineToken}`,
  //   async () => await UserService.getYjTokenAndStatus(authCtx.lineToken),
  //   {
  //     enabled: !!authCtx.lineToken,

  //     onSuccess: (res) => {
  //       if (res) {
          
  //         const loginInfo = { 'token': res.token, 'isNewUser': res.is_new_user };
  //         if (loginInfo?.token) {
  //           const payload = jwt_decode(loginInfo.token) as TokenPayload;
  //           localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
  //           authCtx.setMobileLogin(true);
  //           // authCtx.updateUserInfo();
  //           console.log("[CheckLoginPage] getYjTokenAndStatusAction :"+localStorage.getItem(ORIGINAL_URL));
  //           // document.cookie = `${COOKIE_NAME}=true; path=/`;
  //           //  navigate('/mobile');
  //            //跳轉頁面
  //           // if(localStorage.getItem(ORIGINAL_URL) == "/mobile/profile/main") {
  //           //   navigate("/mobile/profile/main");
  //           // }
  //           // else if(localStorage.getItem(ORIGINAL_URL) == "/mobile/profile/collection") {
  //           //   navigate("/mobile/profile/collection");
  //           // }
  //           // else if(localStorage.getItem(ORIGINAL_URL) == "/mobile/quiz") {
  //           //   navigate("/mobile/quiz");
  //           // }
  //           // else if (localStorage.getItem(ORIGINAL_URL) == "/mobile") {
  //           //   const url = localStorage.getItem(ORIGINAL_URL) || "";
  //           //   navigate(url);
  //           // }
  //           // if(localStorage.getItem(ORIGINAL_URL) != undefined && localStorage.getItem(ORIGINAL_URL) != "") {
           
  //           //     navigate(`${localStorage.getItem(ORIGINAL_URL)}`);
             
              
  //           // }
  //           // else {
  //           //   navigate("/mobile");
  //           // }
  //           navigate(`${localStorage.getItem(ORIGINAL_URL)}`);
  //         }

  //       }

  //     },

  //     onError: (err: any) => {
  //      console.log("error ="+err);
  //     }




  //   }
  // );


  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    // const code = searchParams.get('code');
    extractLineToken();
    // const profile = getProfile(`${code}`);
    // if (code) {
    //   // login(code);
    //   // console.log("code = "+code+" 準備取得 token");
    //   extractLineToken();
    // }
    // TODO else

  }, [location.pathname]);

  return (
    // <MainLayout>
    //   <div className='h-full w-full flex justify-center items-center bg-slate-200'>
    //     <div className="bg-white p-16 rounded shadow min-w-[40vw]">
    //       <LoadingOutlined style={{ fontSize: '3rem' }} />
    //       <div className=' text-xl '>登入中..... </div>
    //     </div>
    //   </div>
    // </MainLayout>
    <></>
  );
};
export default CallbackPage;
