import { post, get, getAuthHeader } from './base';
import { API_URL } from '../configuration';
import { QuizsData,TopicsData } from '../types/CustomType';
import {AttractionData} from '../types/CustomType';

//取得旅遊題型
const getLastQuizDoneData = async (): Promise<AttractionData[]> => {
    const response = await get(`${API_URL}/api/user_itinerarys?query_type=public`, getAuthHeader());
    return await response.data.message;
};

//取得旅遊題型
const getQuizData = async (query_type : string,uuid : string): Promise<QuizsData> => {
    const response = await get(`${API_URL}/api/quizs?query_type=${query_type}&uuid=${uuid}`);
    return await response.data.message;
};
//取得旅遊題型對應的題目集
const getTopicsData = async (query_type : string,quiz_uuid : string): Promise<TopicsData[]> => {
    //console.log(`${API_URL}/api/problems?query_type=${query_type}&quizs_uuid=${quiz_uuid}`);
    const response = await get(`${API_URL}/api/problems?query_type=${query_type}&quizs_uuid=${quiz_uuid}`);
    return await response.data.message;
};

const recodeQuizData = async (payload: object) => {
    return await post(`${API_URL}/api/user_itinerarys`, payload, getAuthHeader())
}

const QuizService = {
    getQuizData,
    getLastQuizDoneData,
    getTopicsData,
    recodeQuizData,
}

export default QuizService;