import { post, get, getAuthHeader } from './base';
import { API_URL } from '../configuration';


// 掃描 QR code，對應集章卡的 API
// const sendQRcode = async (qrcode : StringDecoder): Promise<LoyaltyCardDetectQCodeData> => {
//     const response = await get(`${API_URL}/api/quizs?query_type=${query_type}&uuid=${uuid}`);
//     return await response.data.message;
// };

export const sendCoupon = async (payload: object) => {
    return await post(`${API_URL}/api/myvoucher`, payload, getAuthHeader())
}


