// CustomModal.tsx
import React, { useEffect, useContext, useState,useRef } from 'react';
import { Modal,Button } from 'antd';
import './style/qrcodeScannerStyle.css'
import { sendQRcode } from '../apis/LoyaltyCardService';
import AuthContext from '../context/authContext';
import { useNavigate} from 'react-router-dom';

import {
    BrowserQRCodeReader,
    NotFoundException,
    ChecksumException,
    FormatException,
    BrowserMultiFormatReader,
  } from "@zxing/library";

interface CustomModalProps {
  isVisible: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;  // Include this line
}

const QRScannerV2 : React.FC<CustomModalProps> = ({ isVisible, title, onClose, children}) => {

  // codeReader
  // const codeReader = new BrowserMultiFormatReader();
  const [codeReader, setReader] = useState<any>(new BrowserMultiFormatReader());
  //const [videoInputDevices, setVideoInputDevices] = useState([]);
  // const [selectedVideoDevice, selectVideoDevice] = useState('');

  const [scanning, setScanning] = useState(false);
  // const codeReader = new BrowserMultiFormatReader();
  // let stopScanningFunction = null;

  const [tip, setTip] = useState('');
  //const [processing, setProcessing] = useState(false);
  const processing = useRef(false);

  
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  // useEffect(() => {
    
  //   (async () => {

        
  //       // const videoInputDeviceList = await codeReader.listVideoInputDevices();
  //       // //setVideoInputDevices(videoInputDeviceList);
        
  //       // // console.log("videoInputDeviceList length = "+videoInputDeviceList.length);
  //       // if (videoInputDeviceList.length > 0 ) {
  //       //   try{
  //       //     selectVideoDevice(videoInputDeviceList[1].deviceId);
  //       //   }
  //       //   catch (error) {
  //       //     // selectVideoDevice(videoInputDeviceList[0].deviceId);
  //       //   }
   
  //       // }

     
  //   })();
  // }, [codeReader, selectedVideoDevice]);

   useEffect(() => {
    if(isVisible && !scanning) {
      
      startScanning();
      // handleShow();
    }

    //  if(!isVisible){

     
    //  }
    
  }, [isVisible]);

  useEffect(() => {
    if(codeReader && isVisible) {
    codeReader.getVideoInputDevices()
      .then((videoInputDevices: any[]) => {

        //console.log("鏡頭清單 :"+videoInputDevices.length);

        const backCamera = videoInputDevices.find((device: { label: string; }) =>
          device.label.toLocaleLowerCase().includes('back')
        );

        const frontCamera = videoInputDevices.find((device: { label: string; }) => 
          !device.label.toLocaleLowerCase().includes('back')
        ) || videoInputDevices[0];
        

        if (backCamera) {
          
          const deviceId = backCamera.deviceId;

          codeReader.decodeFromInputVideoDeviceContinuously(
            deviceId,
            'video',
            (result : any, error: any) => {
              if (result) {
                // 偵測到 QR 或條碼
                //console.log(result);
                //setProcessing(true);
                processing.current = true;
                detectQRCodeEvent(result.text);
                // 如果你想在掃描到第一個結果後停止，可以在此調用 stopScanning()
               
              }

              if (error) {
                // 處理錯誤
                console.error(error);
              }
            }
          );
        } else if(frontCamera) {
          
          const deviceId = undefined;
          //setProcessing(false);
          processing.current = false;
          // alert("前! :"+deviceId);
          // console.log("deviceId = "+deviceId);
          // 如果沒有找到後鏡頭，使用默認設備
          codeReader.decodeFromInputVideoDeviceContinuously(
            deviceId,
            'video',
            (result:any, error:any) => {
              if (result && !processing.current) {
                // 偵測到 QR 或條碼
                console.log(result);
                processing.current = true;
                detectQRCodeEvent(result.text);
                //setProcessing(true);
                
                // 如果你想在掃描到第一個結果後停止，可以在此調用 stopScanning()
                //  stopScanning();
              }

              if (error) {
                // 處理錯誤
                // console.error(error);
              }
            }
          );
        }
      })
      .catch((error: any) => {
        console.error(error);
        stopScanning();
      });
    }
  }, [codeReader]);





  ///--------------------------
  
  const startScanning = () => {

    // if (codeReader.isRunning) {
    //   codeReader.reset();
    // }
    setReader(new BrowserMultiFormatReader());

    setScanning(true);

    
  };
   
 

  const detectQRCodeEvent = async (qr_id : string)  => {
    try {

      setTip("已經偵測 QR code，系統處理中...");
      setTimeout(() => {
        onClose();
        setTip("");
        processing.current = false;
        window.location.href = qr_id;
        // setProcessing(false);
      }, 1500);   

      
      // const updatePayload = {"answer": qr_id};

      // await sendQRcode(updatePayload)
      // .then((response) => {
      //     const data = response?.data.message;
      //     // console.log("[qrcode] success response: ", data);
          
      //     // setTip("恭喜! "+data.title+" 搜集到了一個點數!");
      //     setTip(data);
      //     authCtx.updateUserInfo();
      //     // setProcessing(false);
      //     setTimeout(() => {
      //       setTip("");
      //       stopScanning();
      //     }, 2000);   
      //     // setProcessing(false);
      // })
      // .catch((error) => {
      //     console.log("[qrcode] error response: ", error.response.data);
      //     const data = JSON.parse(error.response.data);
      //     setTip("系統提示! "+data.message);
      //     setTimeout(() => {
      //       setTip("");
      //       processing.current = false;
      //       // setProcessing(false);
      //     }, 2000);   
      //     // 
      // });

      // authCtx.updateUserInfo();
     
     
    }
    catch (error) {
        console.log('post qr code error:', error);
    }
  }


  const stopScanning = () => {
    //console.log("stopScanning...");
    // codeReader.reset();
    const videoElement = document.getElementById('video') as HTMLVideoElement;
    const stream = videoElement?.srcObject as MediaStream;
    const tracks = stream?.getTracks();
   
    tracks.forEach(track => track.stop());
    //console.log(stream);
    
    setReader(codeReader?.reset());
    
    
    // setReader(new BrowserMultiFormatReader());
    setScanning(false);
    onClose();
    navigate("/mobile");
  };

  // function resetClick() {
    
  //   setReader(codeReader.reset());

  //   setReader(new BrowserMultiFormatReader());
  //   // setProcessing(false);
  //   onClose();
   
  // }

 
  // const [selectedDeviceId, setSelectedDeviceId] = useState("");
   const [code, setCode] = useState("");
  


  const customFooter = (
    <>
      <Button style={{ backgroundColor: '#179188', color: '#fff' }} onClick={stopScanning}>
        取消
      </Button>
      {/* <Button style={{ backgroundColor: '#179188', color: '#fff' }} onClick={confirmClick}>
        確認集章
      </Button> */}
    </>
  );
 

  return (
    <Modal
      className="centered-title-modal"
      title={title}
      open={isVisible}
      // onCancel={resetClick}
      // onOk={resetClick}
      footer={customFooter}
      closable={false}
      maskClosable={false}
      style={{ top: '50%', transform: 'translateY(-50%)' }}
    >
      
      <>
      {/* <div>
           <video id='video' width='300' height='200' />
        </div> */}

        <div className="video-container">
          <video id='video' />
        </div>

        {/* <label>Result:</label>
        <pre>
          <code id="result">{code}</code>
        </pre> */}

        
        <div id="tip" className="tip-container">{tip}</div>
        

      </>
    </Modal>
  );
};

export default QRScannerV2;
