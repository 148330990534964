import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import Tag from '../types/Tag';

interface AllTagsCollapseProps {
    setCollapseTitle: (tagName: string) => void;
    showAttractionsDrawer: (title: string, view: string,tagID:string) => void;
    showItinerariesDrawer: (title: string, view: string) => void;
    setTagID_: (ID: string) => void;
    tags:Tag[]|null;
    tagName:string;
    _tagID:string;
  
}

const AllTagsCollapse: React.FC<AllTagsCollapseProps> = ({ setCollapseTitle, showAttractionsDrawer,showItinerariesDrawer,setTagID_,tags,tagName,_tagID}) => {

    const [tagUsed, setTagUsed] = useState('');
    const [tagID, setTagID] = useState('');

    useEffect(() => {
        // console.log("選擇 tagID = "+_tagID);
        setTagID(_tagID);
    }, [_tagID]);

    const handlerButtonEvent = (tagName: string,tagID:string) => {
        setTagUsed(tagName);
        setCollapseTitle(`關鍵字瀏覽: #${tagName}`);
        setTagID(tagID);
        setTagID_(tagID);
    }

    return (
        <>
            <div className='flex flex-wrap justify-start w-full pr-2'>
                {
                    tags?.map((value,index)  => (   
                        <Button
                            key={index}
                            shape="round"
                            className=
                            {
                                value.id != _tagID ?
                                'text-white bg-secondary m-1 hover:bg-primary hover:text-white' :
                                ' text-white bg-primary m-1 hover:bg-primary hover:text-white'
                            }
                            onClick={
                               
                                () =>  value.id != _tagID ? handlerButtonEvent(value.name,value.id) : showAttractionsDrawer (`類別: #${value.name}`, 'attractions',value.id)
                                
                            }
                        >
                            #{value.name}
                        </Button>
                    ))
                }
              
            </div>
            <div className='flex justify-between '>
                <div className='w-1/3 flex flex-col justify-end item-start '>
                    <button className=' text-primary border-primary border rounded-lg bg-white h-1/3 w-3/4' onClick={() => { showAttractionsDrawer (`類別: #${tagUsed}`, 'attractions',tagID) }}>
                        <FontAwesomeIcon icon={faRectangleList} />
                        景點清單
                    </button>
                </div>
                <div className='w-2/3 flex flex-col justify-end items-end font-bold text-primary pt-10'>
                    <div className='text-base'>
                        <span className='text-slate-700'>猶豫不決?</span>
                        <Link to={'/mobile/quiz'} className='text-primary' >
                            或是做個小測驗
                        </Link>
                        <FontAwesomeIcon icon={faLongArrowAltRight} className='pl-2 ' />
                    </div>
                    <div className=' text-base'>
                        <span className=' text-slate-700'>或者看看我們的</span>
                        <span onClick={() => { showItinerariesDrawer('所有經典路線', 'itinerary') }}>經典行程</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllTagsCollapse;
