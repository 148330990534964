import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Button, Collapse, Drawer, FloatButton, Tour  } from 'antd';
import type { CollapseProps, TourProps } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import './style.css';
import CheckLoginPage from '../CheckLoginPage/CheckLoginPage';
import MobileLayout from '../../layouts/MobileLayout';
import Attractions from '../../components/Attractions'
import AttractionsV2 from '../../components/AttractionsV2'
import Itinerary from '../../components/Itinerary'
import AllTagsDrawer from '../../components/AllTagsDrawer'
import AllTagsCollapse from '../../components/AllTagsCollapse'
import ItineraryCollapse from '../../components/ItineraryDrawer'
// import LandingAskModal from '../..//components/LandingAskModal'
import LandingAskModalV2 from '../..//components/LandingAskModalV2'
import LeafletMap from '../../components/LeafletMap';
import Tag from '../../types/Tag';
import { useQuery, QueryCache } from 'react-query';
// import { AttractionType } from '../../types/ItineraryType';
import TagService from '../../apis/TagService';
import AttractionService from '../../apis/AttractionService';
//google map
// import MapComponent from '../../components/MapComponent';
// import MapComponent, { GmapHandler } from '../../components/MapComponent';
// import Gmap from '../../components/MapComponent';
import QuizService from '../../apis/QuizService';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';
import ItineraryService from '../../apis/ItineraryService';
import { IS_MOBILE_LOGIN,ORIGINAL_URL,COOKIE_NAME,COOKIE_LOGUT_NAME,LINE_CHANNEL_ID,LINE_REDIRECT_URI } from '../../configuration'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faHouse, faHashtag, faMap, faStreetView, faQuestion } from '@fortawesome/free-solid-svg-icons';
// LIFF
import liff from '@line/liff'; // 追加
import { getYjTokenAndStatus,enableTour } from '../../apis/member';
import { YJ_ACCESS_TOKEN, USER_INFO_KEY } from '../../configuration'
import jwt_decode from 'jwt-decode';
import AuthContext from '../../context/authContext'; //內文
interface TokenPayload {
  sub: string;  //TODO
  name: string;
  picture: string;
}
import { useNavigate } from 'react-router-dom';
import { UserInfoContext } from '../../context/userInfoContext';
import { MapInfoContext } from '../../context/mapInfoContext';
//處理結果
import { ProcessMapData, ResetMapData } from '../../lib/MapLib';
import { AttractionData, ItineraryDetailType, AuthInfoData } from '../../types/CustomType';
import MapComponent_v2 from '../../components/MapComponent_v2';

import UserService from '../../apis/UserService';

const { Footer } = Layout;
const { Panel } = Collapse;


type RouteParams = {
  attractionName : string | undefined;
  itineraryUUID : string | undefined;
  itineraryName : string | undefined;
};



const MobilePage = () => {

  const {attractionName,itineraryUUID,itineraryName} = useParams<RouteParams>();

  const [isSingle, setIsSingle] = useState<boolean>(false);


  const [isLandingAskOpen, setIsLandingAskOpen] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerView, setDrawerView] = useState('attractions');
  const [customized, setCustomized] = useState<boolean>(false);
  const [collapseView, setCollapseView] = useState('allTagsCollapse');
  const [drawerTitle, setDrawerTitle] = useState('');
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const [collapseTitle, setCollapseTitle] = useState('');
  const [isMyLocation, setMyLocation] = useState<boolean>(false);
  const [collapseItineraryId, setCollapseItineraryId] = useState('');
  // const [attrActionsByItinerary, setAttractionsByItinerary] = useState<AttractionData[]>([]);
  const [itinerary, setItinerary] = useState<ItineraryDetailType>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagID, setTagID] = useState('');
  const [collapseActiveKey, setCollapseActiveKey] = useState(['1']);
  const [stepOpen, setStepOpen] = useState<boolean>(false);
  const [mualStepOpen, setMualStepOpen] = useState<boolean>(false);
  const locationRef = useRef(null);
  const tagsRef = useRef(null);
  const attractionRef = useRef(null);
  const customPathRef = useRef(null);
  //const [tagID_, setTagID_] = useState('');


  const [tagName, setTagName] = useState('');
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [mapMode, setMapMode] = useState<boolean>(false);

  const [randomValue, setRandomValue] = useState<number>(0);


  const mapContext = useContext(MapInfoContext);
  const userContext = useContext(UserInfoContext);
  // const gmapRef = React.useRef<GmapHandler>(null);
  //Liff
  // const [displayName, setDisplayName] = useState('');

  const [lineToken, setLineToken] = useState<string>('');

  // 由下往上滑的 Panel 

  const [isOpenPanel, setIsOpenPanel] = useState<number>(0);

  const handlePanelToggle = () => {
    if(isOpenPanel == 0) {
      setIsOpenPanel(1);
    } else {
      setIsOpenPanel(0);
    }
    
  };

  const showDrawer = (title: string, view: string, customized = false) => {
    setDrawerTitle(title);
    setDrawerView(view);
    setCustomized(customized);

    // if(customized && !userContext.quizDone) {
    //   //取得該使用者最後一次測驗的資料
    //   console.log("客製化行程要查詢!!!")
    //   getMyQuizResultEvent();
    // } else {
    //   setOpenDrawer(true);
    // }
    setOpenDrawer(true);
    // openDrawer ? "" : setOpenDrawer(true);
    
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const closeCollapsePanel = () => {
    setCollapseActiveKey([]);
  };


  const openCollapse = (title: string, view: string) => {
    //console.log("[openCollapse] view name = "+view);
    setCollapseView(view);
    setCollapseTitle(title);
    showCollapse ? "" : setShowCollapse(true);
  };

  const onAllInineraryDrawer = () => {
    setOpenDrawer(false);
    showDrawer('所有經典路線', "itinerary");
    setIsOpenPanel(0);
    setOpenDrawer(true);
  };

  const openAllTagCollapse = (title: string, view: string) => {
    console.log("[openAllTagCollapse] view name = "+view);
    setCollapseView(view);
    setCollapseTitle(title);
    setIsOpenPanel(0);
    showCollapse ? "" : setShowCollapse(true);
    
  };

  //預設首頁
  // const openLandingAskModalCollapse = (title: string, view: string, tagID: string) => {
  //   //console.log("[openCollapse] view name = "+view);
  //   setCollapseView(view);
  //   setCollapseTitle(title);
  //   setTagID(tagID);
  //   showCollapse ? "" : setShowCollapse(true);

  // };


  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);



  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  const handleLogin = () => {
    // const lineAuthUrl = LINE_LOGIN_URI;
    // const clientId = LINE_LOGIN_CLIENT_ID;
    // const redirectUri = LINE_REDIRECT_URL;
    // window.location.href = `${lineAuthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=STATE&scope=profile%20openid%20email`;

    //2000457318-ObG6q2PX (測試)
    //2000457318-rWmXJdL2(正式)

    // async function initializeLiff() {
    //   try {
    //     await liff.init({ liffId: '2000457318-rWmXJdL2' });

    //     if (!liff.isLoggedIn()) {
    //       // console.log("尚未登入");
    //       liff.login();
    //       localStorage.setItem(IS_MOBILE_LOGIN, `true`)
    //       authCtx.setMobileLogin(true);


    //     } else {
    //       // console.log("已經登入");
    //       liff_login();
    //     }
    //   } catch (error) {
    //     console.error('LIFF initialization failed', error);
    //   }
    // }

    // initializeLiff();
  };

  // useEffect(() => {
  //   if (lineToken != "" && lineToken != null) {
  //     // console.log("[useEffect] profile.lineIDToken = "+lineToken);
  //     getYjTokenAndStatusAction();
  //   }

  // }, [lineToken]);

  const [loggedIn, setLoggedIn] = useState(false);
  const [logOut, setLogOut] = useState(false);

  useEffect(() => {



    // const isLoggedIn = document.cookie.includes(`${COOKIE_NAME}`);
    // setLoggedIn(isLoggedIn);

    // const isLogOut = document.cookie.includes(`${COOKIE_LOGUT_NAME}`);
    // setLogOut(isLogOut);

  //  console.log("token :"+localStorage.getItem(YJ_ACCESS_TOKEN))



  }, [loggedIn,authCtx.isUpdateInfo]);




  const liff_login = async () => {
    try {
      // const profile = await getProfile(`${code}`);

      const profile = {
        "lineId": (await liff.getProfile()).userId,
        "lineIDToken": liff.getIDToken() ?? "",
      }

      // setLineToken(profile.lineIDToken);
      setLineToken(profile.lineIDToken);


    } catch (error) {
      console.error(error);
    }
  }


  const enableTourEvent = async (enable : any)  => {

    try {
        const updatePayload = {
            "tour_check": enable, 
        };

        await enableTour(updatePayload)
        .then((response) => {
       
            // console.log("[qrcode] success response: ", response?.data);
            
        })
        .catch((error) => {
            // console.log("[qrcode] error response: ", error);
           
        });

        authCtx.updateUserInfo();
     
     

    }
    catch (error) {
        console.log('post qr code error:', error);
    }
  }



  // const { refetch: getYjTokenAndStatusAction } = useQuery<AuthInfoData, Error>(
  //   `queryKey${lineToken}`,
  //   async () => await UserService.getYjTokenAndStatus(lineToken),
  //   {
  //     enabled: !!lineToken,

  //     onSuccess: (res) => {
  //       if (res) {
          
  //         const loginInfo = { 'token': res.token, 'isNewUser': res.is_new_user };
  //         if (loginInfo?.token) {
  //           const payload = jwt_decode(loginInfo.token) as TokenPayload;
  //           // console.log("伺服器拿到的 token :"+loginInfo.token);
  //           localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
  //           // localStorage.setItem(ORIGINAL_URL, location.pathname)
  //           authCtx.updateUserInfo();
  //           // console.log("[MobilePage] getYjTokenAndStatusAction");
  //           //navigate('/mobile');
  //           console.log("[MobilePage] 原本路徑"+localStorage.getItem(ORIGINAL_URL));
  //           console.log("token :"+localStorage.getItem(YJ_ACCESS_TOKEN))
          
  //           if(localStorage.getItem(ORIGINAL_URL)!= 'undefined' && localStorage.getItem(ORIGINAL_URL)) {
  //             const url = localStorage.getItem(ORIGINAL_URL);
  //             navigate( `${url}`);
  //           }
           
           
  //         }


  //       }

  //     }
  //   }
  // );

  

  useEffect(() => {

    if(!authCtx.isMobileLogin) {
      localStorage.setItem(ORIGINAL_URL, "/mobile");
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
    } else {
      authCtx.updateUserInfo();
    }


    if (userContext.quizDone == true) {
          // console.log("客製化路線");
          setIsLandingAskOpen(false)
          if (userContext.quizDoneFirstChecked == true) {
            showDrawer('客製化路線景點介紹', 'attractions_path', true);

            recodeQuizResultEvent(userContext.recordQuizUUID,userContext.recordQuizAttractions,userContext.recordQuizAnwser);
            userContext.setQuizDoneFirstCheckedStatus(false);
          }
        
        }
    else {

      if(attractionName != undefined && attractionName != ''){
        setIsLandingAskOpen(false)
        setIsSingle(true)
      } else {
        setIsSingle(false)
        setIsLandingAskOpen(true)
      }


      if(itineraryUUID != undefined && itineraryUUID != '' && itineraryName != undefined && itineraryName != ''){
        setIsLandingAskOpen(false)
        openItineraryCollapse(itineraryName, 'itineraryCollapse', itineraryUUID);
      } else {
        setIsLandingAskOpen(true)
      }

      if (tagID == "") {
        ResetMapData(mapContext);//重置所有地圖上資訊

        // queryAttractionByTag();
        // console.log("itineraryUUID ="+itineraryUUID);
        if(itineraryUUID == undefined ){
          newQueryAttractionByTag(tagID);
        }
        // newQueryAttractionByTag(tagID);
      }
        
    }

  
  }, [location.pathname]);//tagID,

  useEffect(() => {
    // console.log("Tag 變化");
    if (tagID != "") {
      setIsOpenPanel(0);
      // queryAttractionByTag();
      newQueryAttractionByTag(tagID);
    }
  }, [tagID]);

  

  //紀錄個人行程
  const recodeQuizResultEvent = async (quiz_uuide:string,attractions:string,description:string)  => {

    try {
        const updatePayload = {
            "quiz_uuid":quiz_uuide, 
            "attractions":attractions,
            "description":description,
        };

        // console.log("quiz_uuid: "+userContext.recordQuizUUID);
        // console.log("description: "+description);
        // console.log("attractions: "+attractions);

        await QuizService.recodeQuizData(updatePayload)
        .then((response) => {
       
            //console.log("[recodeQuizResultEvent] success response: ", response?.data);

            // userContext.recordQuizUUID = "";
            // userContext.recordQuizAttractions = "";
            // userContext.recordQuizAnwser = "";

          userContext.setRecordQuizUUID("");
          userContext.setRecordQuizAnwser("");
          userContext.setRecordQuizAttractions("");
            
        })
        .catch((error : any) => {
           //console.log("[recodeQuizResultEvent] error response: ", error);
           
        });

    }
    catch (error) {
        //console.log('post qr code error:', error);
    }
  }

  //取得最後一筆個人客製化行程
  const getMyQuizResultEvent = async ()  => {

    try {
        

        await QuizService.getLastQuizDoneData()
        .then((response) => {

          if(response){
            // console.log("最後一筆資料的景點數量: ", response.length);
            ProcessMapData(mapContext,response, true);
            setOpenDrawer(true);
          }
       
          
            
        })
        .catch((error : any) => {
           //console.log("[recodeQuizResultEvent] error response: ", error);
           
        });

    }
    catch (error) {
        //console.log('post qr code error:', error);
    }
  }

  const openItineraryCollapse = (title: string, view: string, uuid: string) => {

    // console.log("觸發 openItineraryCollapse [顯示路徑的景點]");

    // mapContext.setInIsUpdate(false);

    if(collapseItineraryId != uuid) {
      mapContext.setInIsUpdate(false);
    }
    setShowCollapse(false);
    setCollapseView(view);
    setCollapseTitle(title);
    setCollapseItineraryId(uuid);
    // console.log("景點數量 :"+mapContext.attraction.length);
    // queryItineraryDetailInfo()
    showDrawer(title, 'attractions_path', false);
    // console.log("[openItineraryCollapse] view ="+view+" title = "+title);
  };

  const showAllAttractionByItineraryDrawer = (title: string | null, view: string, data: AttractionData[] | null) => {

    // console.log("觸發 showAllAttractionByItineraryDrawer");

    title ? setDrawerTitle(title) : setDrawerTitle("");
    setDrawerView(view)
    // data ? setAttractionsByItinerary(data) : setAttractionsByItinerary([]);
    // mapContext.setInIsUpdate(false);
    openDrawer ? "" : setOpenDrawer(true);

    if (data) {
      
      ProcessMapData(mapContext, data, true);  //這裡才會是更新玩
    }

  };


  const showAttractionsDrawer = (title: string | null, view: string, tagID: string) => {
  //  console.log("[showAttractionsDrawer] title = " + title);
    title ? setDrawerTitle(title) : setDrawerTitle("");
    setTagID(tagID);
    setDrawerView(view);
    // queryAttractionByTag();
    //  console.log("tag id--> " + tagID);
     openDrawer ? "" : setOpenDrawer(true);

  };

  


  const newQueryAttractionByTag = async (tagID:string)  => {
    try {
     
      await AttractionService.getData(tagID)
      .then((response) => {

        // console.log("[API][查詢 Tag 資訊][成功][回應資訊]:", response.length);
        if (response?.length != 0) {

          if (tagID != "") {
            showDrawer(`關鍵字瀏覽: #${tags[parseInt(tagID)-1].name}`, "attractions");
          }
            // openDrawer ? "" : setOpenDrawer(true);
          
          ProcessMapData(mapContext, response, false);
          // setDrawerView("attractions");

        } else {
          ResetMapData(mapContext);
        }

     
      })
      .catch((error : any) => {
         //console.log("[API][查詢 Tag 資訊][錯誤]:", error);
         
      });

    }
    catch (error) {
      //console.log('[API][查詢 Tag 資訊][錯誤]:', error);
    }
  }

  //最佳解
  // const { refetch: queryAttractionByTag } = useQuery<AttractionData[], Error>(

  //   `queryKey${tagID}`,
  //   async () => await AttractionService.getData(tagID),
  //   {
  //     enabled: !!tagID,
  //     onSuccess: (res) => {
  //       // drawerTitle

  //       setDrawerTitle(collapseTitle);
  //       console.log("有變化:" + res?.length +" 顯示種類"+drawerView);
  //       // setAttractionsByItinerary(res);
  //       if (res?.length != 0) {

  //         if (tagID != "")
  //           openDrawer ? "" : setOpenDrawer(true);
          
  //         ProcessMapData(mapContext, res, false);
  //         // setIsSingle(false);
  //         setDrawerView("attractions");

  //       } else {
  //         ResetMapData(mapContext);
  //       }
  //     }
  //   }
  // );


  const { refetch: queryItineraryDetailInfo } = useQuery<ItineraryDetailType, Error>(

    `queryKey-${collapseItineraryId}`,
    async () => await ItineraryService.findAppoint(collapseItineraryId),
    {
      enabled: !!collapseItineraryId,
      onSuccess: (res) => {
        setItinerary(res);
        const markDataLocation = res?.way_attractions;
        if (markDataLocation) {
          // ResetMapData(mapContext);
          // console.log("[更新路城資訊]")
          ProcessMapData(mapContext, markDataLocation, true);
        }
        if (collapseItineraryId != "") {
          showCollapse ? "" : setShowCollapse(true);
          // console.log(res.way_attractions);
          // ProcessMapData(mapContext,data,true);
        }
        // showCollapse ? "" : setShowCollapse(true);
      }
    }
  );

  const genExtra = () => (
    <div className="flex items-center">
      <button className=' text-primary border-primary border rounded-lg bg-white mt-1' >
        <FontAwesomeIcon icon={faRectangleList} className='mr-1' />
        查看詳細資訊
    </button>
    </div>
    
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: collapseTitle,
      showArrow: true,
      extra: genExtra(),
      children:
        <>
          {collapseView === 'allTagsCollapse' && 
          <AllTagsCollapse setCollapseTitle={setCollapseTitle} 
            showAttractionsDrawer={showAttractionsDrawer} 
            showItinerariesDrawer={showDrawer} 
            setTagID_={setTagID} 
            tags={tags} 
            tagName={tagName} 
            _tagID={tagID} />
          }
          {collapseView === 'itineraryCollapse' && <ItineraryCollapse showDrawer={showAllAttractionByItineraryDrawer} openCollapse={openCollapse} itinerary_={itinerary} /> }
        </>
      // children: <AllTagsCollapse setCollapseTitle={setCollapseTitle} showDrawer={showDrawer} />,

    }
  ]
  const onChange = (key: string | string[]) => {
    // console.log(key);
    if(isOpenPanel == 0) {
      setIsOpenPanel(1);
    } else {
      setIsOpenPanel(0);
    }
  };


  //tag------------------------------------------------------
  const { isLoading, isSuccess, isError, data } = useQuery<any, Error>(
    ["query-default-tags"],
    async () => {
      return await TagService.findDefault();
    },
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        setTags(res.message);
      },
      onError: (err: any) => {
        console.log(err);
      }
    }
  );


  const steps: TourProps['steps'] = [
    {
      title: '定位',
      description: '定位您所在位置.',
      placement: 'left',
      nextButtonProps: {
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },
      target: () => locationRef.current,
    },
    {
      title: '所有標籤分類',
      description: '查看所有標籤分類',
      placement: 'left',
      nextButtonProps: {
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },
      prevButtonProps:{
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },
      target: () => tagsRef.current,
    },
    {
      title: '所有經典路線',
      description: '查看所有經典路線',
      placement: 'left',
      prevButtonProps:{
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },nextButtonProps: {
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },
      target: () => attractionRef.current,
    },
    {
      title: '所有客製化路線',
      description: '查看所有客製化路線',
      placement: 'left',
      prevButtonProps:{
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },nextButtonProps: {
        style:{
          backgroundColor:'#179188',
          color:'#FFFFFF',
        }
      },
      target: () => customPathRef.current,
    },
  ];

  

  return (
    
   
    <>
      { 
      
      <MobileLayout>
       
      <div
        className='w-full bg-white flex flex-grow h-full relative'
      >
        {!authCtx.userInfo.tour_check && authCtx.isMobileLogin  ?
          
          <Tour 
            open={stepOpen} 
            steps={steps} 
            className=' max-w-min'
            onClose={() => authCtx.isLogin && enableTourEvent(!authCtx.userInfo.tour_check) }
          />
          :
          <Tour 
            open={mualStepOpen} 
            steps={steps} 
            className=' max-w-min'
            onClose={() => setMualStepOpen(false) }
          />
        }
       
        <Drawer title={drawerTitle} placement="right" onClose={onCloseDrawer} open={openDrawer} >
          {drawerView === 'itinerary' && <Itinerary onClose={onCloseDrawer} openCollapse={openItineraryCollapse} />}
          {drawerView === 'attractions' && <Attractions data={mapContext.attraction} />}
          {drawerView === 'attractions_path' && customized !== true && <AttractionsV2 data={mapContext.attraction} customized={false} lodaing={false} randomForCustom={0} />}
          {drawerView === 'attractions_path' && customized === true && <AttractionsV2 data={mapContext.attraction} customized={true} lodaing={!userContext.quizDone} randomForCustom={randomValue}/>}
          {drawerView === 'allTagsDrawer' && <AllTagsDrawer onClose={onCloseDrawer} openCollapse={openAllTagCollapse}  onAllInineraryDrawer={onAllInineraryDrawer} setTagID={setTagID} />}
        </Drawer>

        {/* {isError && <div>失敗</div>} */}
        {/* {isSuccess && <LandingAskModal isLandingAskOpen={isLandingAskOpen} setIsLandingAskOpen={setIsLandingAskOpen} openCollapse={openLandingAskModalCollapse} tags={tags}/>} */}
        {isLandingAskOpen &&
          <LandingAskModalV2 setIsLandingAskOpen={setIsLandingAskOpen} openCollapse={openCollapse} openItineraryCollapse={openItineraryCollapse} tags={tags} setTagID={setTagID} setStepOpen={setStepOpen} />
        }
        {/* {isLoading && <div>載入</div>} */}

        <div className='flex justify-center items-center font-semibold flex-grow'>
          {/* <LeafletMap /> */}
          <MapComponent_v2  attractions={mapContext.attraction} isDrawOrder={mapContext.isDrawPath} triggerCurrent={isMyLocation} setMyLocation={setMyLocation} triggerSingle={isSingle} singleName={attractionName}  />
         
          <div className='flex justify-center items-center font-semibold flex-grow '>
          
            <Layout className='map-title bg-transparent w-full'>
              <div className=' text-primary text-4xl pt-20 relative'>
                <Button
                  shape='circle'
                  ref={locationRef}
                  className=' bg-[#FFD653] absolute top-[80px] right-[10px] transition hover:ease-in-out hover:scale-150 '
                  icon={<FontAwesomeIcon icon={faLocationArrow} style={{ color: "#ffffff", backgroundColor: "#FFD653" }} onClick={() => { 
                    setIsOpenPanel(0);
                    setMyLocation(!isMyLocation) 
                  }} />}
                />
            
                <Button
                  shape='circle'
                  ref={tagsRef}
                  className=' bg-[#EB9267] absolute top-[120px] right-[10px] transition hover:ease-in-out hover:scale-150'
                  icon={<FontAwesomeIcon icon={faHashtag} style={{ color: "#ffffff", backgroundColor: "#EB9267" }} />}
                  onClick={() => {
                    setIsOpenPanel(0);
                    showDrawer('所有分類', 'allTagsDrawer');
                  }}
                />
                <>
                <Button
                  shape='circle'
                  ref={attractionRef}
                  className=' bg-[#037ef3] absolute top-[160px] right-[10px]  transition hover:ease-in-out hover:scale-150'
                  icon={<FontAwesomeIcon icon={faMap} style={{ color: "#ffffff", backgroundColor: "#037ef3" }}
                    onClick={() => {
                      setIsOpenPanel(0);
                      showDrawer('所有經典路線', "itinerary");
                    }} />}
                />
                </>
                <>
                <Button
                    shape='circle'
                    ref={customPathRef}
                    className=' bg-[#74d2e7] absolute top-[200px] right-[10px] transition hover:ease-in-out hover:scale-150 '
                    icon={<FontAwesomeIcon icon={faStreetView} style={{ color: "#ffffff", backgroundColor: "#74d2e7" }} />}
                    onClick={() => {  
                      console.log("我按了客製化路線景點介紹")
                      userContext.setQuizDoneStatus(false);
                      mapContext.setInIsUpdate(false);
                      // ResetMapData(mapContext);
                      setRandomValue(Math.random());
                      // ProcessMapData(mapContext, userContext.quizMapResult, true);
                      showDrawer('客製化路線景點介紹', 'attractions_path', true);
                    }}
                  />
                </>
                <>
                <Button
                  shape='circle'
                  
                  className=' bg-[#9D9D9D] absolute top-[240px] right-[10px]  transition hover:ease-in-out hover:scale-150'
                  icon={<FontAwesomeIcon icon={faQuestion} style={{ color: "#ffffff", backgroundColor: "#9D9D9D" }}
                    onClick={() => {
                      setIsOpenPanel(0);
                      setMualStepOpen(true);
                      
                    }} />}
                />
                </>
                
                {/* {
                  userContext.quizDone == true && <Button
                    shape='circle'
                    className=' bg-[#74d2e7] absolute top-[200px] right-[10px] transition hover:ease-in-out hover:scale-150 '
                    icon={<FontAwesomeIcon icon={faStreetView} style={{ color: "#ffffff", backgroundColor: "#74d2e7" }} />}
                    onClick={() => {
                      ProcessMapData(mapContext, userContext.quizMapResult, true);
                      showDrawer('客製化路線景點介紹', 'attractions_path', true);
                    }}
                  />
                } */}
                {
                  (mapContext.zoom <= 14 && !isSingle ) ? <h5 style={{ color: 'white' }}>永靖旅遊地圖</h5> : <h5>永靖旅遊地圖</h5>
                }
                
              </div>
            </Layout>
            
          </div>
          
        </div>
       
      </div>
      
      <Footer
        className='w-full flex flex-col justify-end items-center p-0  absolute bottom-0 bg-transparent'
      // max-w-[550px] min-w-[375px]
      >
        {
          showCollapse ?
            <>
              <div className=" shadow-md shadow-gray-500 w-full rotate-180 h-1"></div>
              <Collapse 
              activeKey={isOpenPanel} 
              items={items} 
              defaultActiveKey={collapseActiveKey} 
              bordered={true} 
              onChange={onChange} 
              expandIconPosition={'end'} 
              className=' w-full bg-white '/>
                
            </> : ""
        }
       
      </Footer>
     
      </MobileLayout >
     
      } 
    </>
  )
};
export default MobilePage;
