import React, { useEffect, useState, useContext, useRef } from 'react';
import { Image, Button, Drawer } from 'antd';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { InfoCircleFilled } from '@ant-design/icons';
import Attractions from './Attractions'
import bgFootprint from '../assets/images/footprint.svg';
import arrowLineDown from '../assets/images/arrow-line-down.svg';
import { AttractionData } from '../types/CustomType';
import QuizService from '../apis/QuizService';
import { MapContext } from '@react-google-maps/api';
import { MapInfoContext } from '../context/mapInfoContext';
//處理結果
import { ProcessMapData, ResetMapData } from '../lib/MapLib';

interface Props {
    data: AttractionData[];
    customized?: boolean;
    lodaing?:boolean;
    randomForCustom:number;
}

const AttractionsV2: React.FC<Props> = ({ customized,lodaing,randomForCustom }) => {

    const mapContext = useContext(MapInfoContext);
    

    // const [attractions, setAttractions] =  useState<AttractionData[]>([]);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [isDisplay, setIsDisplay] = useState<boolean>(false);
    const [attractionInfo, setAttractionInfo] = useState<AttractionData[]>([]);
    const onCloseDrawer = () => {
        setOpenDrawer(false);
        
    };
    const showDrawer = (title: string) => {
        setDrawerTitle(title);
        openDrawer ? "" : setOpenDrawer(true);
    };

    useEffect(() => {
        // console.log("行程 data = "+mapContext.attraction.length+" 是否要載入:"+lodaing);
        if(lodaing) {
            getMyQuizResultEvent();
        } else {
            // setAttractions(mapContext.attraction);
            // setIsDisplay(true)
        }
    }, [randomForCustom]);

    

    const getMyQuizResultEvent = async ()  => {

    try {
        

        await QuizService.getLastQuizDoneData()
        .then((response) => {

          if(response){
            // console.log("最後一筆資料的景點數量: ", response.length);
            // data = response;
            // mapContext.setInIsUpdate(false);
            ProcessMapData(mapContext,response, true);
            // setAttractions(response);
            // setIsDisplay(true);
          }
       
          
            
        })
        .catch((error : any) => {
           console.log("[recodeQuizResultEvent] error response: ", error);
           
        });

    }
    catch (error) {
        //console.log('post qr code error:', error);
    }
  }

    return (
        <div className='bg-repeat-y h-full pl-4 bg-[3rem]' style={{ backgroundImage: `url(${bgFootprint})` }}>
            {customized == true &&
                <div className='text-base pb-4 py-1'>
                    <span className='text-slate-700 font-semibold'>想再做一次小測驗?</span>
                    <Link to={'/mobile/quiz'} className='text-primary font-semibold pl-2' >
                        GO
                        <FontAwesomeIcon icon={faLongArrowAltRight} className='pl-2 ' />
                    </Link>
                </div>
            }
            <Drawer title={drawerTitle} placement="right" onClose={onCloseDrawer} open={openDrawer} >
                {
                    attractionInfo && <Attractions data={attractionInfo} />
                }
            </Drawer>
            {
                
                ( mapContext.isUpdate ) && 
                
                <>

                {mapContext.attraction.map((attraction, index) => (
                <div key={index} className='py-2'>
                    <div 
                        className='flex'
                        onClick={() => {
                            setAttractionInfo([attraction]);
                            showDrawer(`${attraction.title}`);
                        }}
                    
                    >
                        <div className='w-1/3 flex-col'>
                            <div className=' h-4/5 flex justify-center items-center relative'>
                                <div className=' text-xl rounded-full w-1/4 h-1/4.5 absolute top-0 left-0 z-0 text-center bg-fourth text-sixth'>
                                    {index + 1}
                                </div>
                                <Image
                                    preview={false}
                                    className='min-h-[92px] min-w-[92px] max-h-[92px] max-w-[92px] z-10  rounded-full border border-white bg-fifth shadow-md'
                                    // src={`/images/attraction_markers/${attraction.maker_slug}`}
                                    src={attraction.icon_slug!=null ? attraction.icon_slug : `https://storage.googleapis.com/yongjing-storge/default/map_icon/${attraction.maker_type}.png`}
                                />
                            </div>
                            <div className='flex justify-center'>
                                <Button
                                    className="text-white bg-primary"
                                    shape="round"
                                    icon={<InfoCircleFilled />}
                                    onClick={() => {
                                        setAttractionInfo([attraction]);
                                        showDrawer(`${attraction.title}`);
                                    }}
                                >
                                    more
                                </Button>
                            </div>
                            </div>
                            <div className='w-2/3 flex-col'>
                                <div className='text-xl font-black'>
                                    {attraction.title}
                                </div>
                                <div className=' text-base text-secondary h-[74px] w-full overflow-auto'>
                                    {attraction.description}
                                </div>
                                <div className='text-base flex justify-end pt-2'>
                                    {
                                        attraction.tags.map((value, index) => (
                                            <div className='text-fifth' key={index}>#{value.name}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            mapContext.attraction.length != index + 1 && <div className=' flex justify-center'>
                                <Image
                                    preview={false}
                                    src={arrowLineDown}
                                ></Image>
                            </div>
                        }
                    </div>
                ))}
                </>
            }
            
        </div >
    );
};

export default AttractionsV2;
