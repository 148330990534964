import React, { useEffect, useState } from 'react';
import { Image, Button } from 'antd';
// import MobileLayout from '../../layouts/MobileLayout';
import quizIndex from '../assets/images/quiz/quiz_index.png'
import { AttractionData } from '../types/CustomType';


interface AnswerOptionData {
    option_title: string;
    option_attraction: AttractionData;
}

interface QuizProps {
    nextQusetion: (questionNo: number, userAnswer: number, userAnswerattraction: AttractionData,questionTitle:string,questionAnswer:string) => void;
    question: string;
    image_slug: string;
    answer: AnswerOptionData[];
    questionNo: number;
    userAnswerResult: number[];
}

const QuizLayout2: React.FC<QuizProps> = ({ nextQusetion, question, image_slug, answer, questionNo, userAnswerResult }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [optionIcon1, setOptionIcon1] = useState<any>();

    // const image_1 = image_slug+'.png';
    // const image__1 = require('../assets/images/quiz/'+image_1);

    useEffect(() => {
        // Loading();
    }, [question]);

    async function Loading() {
        setLoading(false);
        Async();
        setLoading(true);
    }

    function Async() {
        return new Promise((res) => {
            //setOptionIcon1(require('../assets/images/quiz/' + image_slug + '.webp'));
            setOptionIcon1(image_slug);
        })
    }

    return (
        <>
            {!loading && <p>載入資料中...</p>}
            {loading && (
                <div className='text-center w-full flex-grow flex flex-col relative bg-sixth'>
                    <div className='flex flex-col justify-center items-center p-3 flex-1   ' >
                        <div className='flex pb-2 sm:p-4 text-xl sm:text-2xl text-black font-extrabold'>
                            旅遊小測驗
                        </div>
                        <div className='flex pb-2 sm:p-4 min-h-5 sm:min-h-8 text-lg sm:text-xl text-tertiary text-left overflow-y-auto overflow-hidden'>
                            {question}
                        </div>
                    </div>
                    <div className='flex justify-center items-center  px-4 flex-1  '>
                        <Image
                            preview={false}
                            className='object-contain max-w-[160px] max-h-[160px] sm:max-w-[280px] sm:max-h-[280px] '
                            src={require('../assets/images/quiz/' + image_slug + '.webp')}
                        />
                    </div>
                    {/* hover:!border-green-800 */}
                    <div className='flex flex-col justify-center items-center text-xl  text-fourth  flex-1 text-left overflow-y-auto overflow-hidden '>
                        <div className='flex flex-col justify-around items-center w-full p-4'>
                            <Button shape="round" className={`w-11/12 h-8 sm:h-9 bg-seventh text-white mb-3 align-cent text-base md:text-xl font-semibold hover:bg-seventh border-amber-600 ${userAnswerResult[questionNo] == 1 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 1, answer[0].option_attraction,question,answer[1].option_title) }}>
                                {answer[0].option_title}
                            </Button>
                            <Button shape="round" className={`w-11/12 h-8 sm:h-9 bg-seventh text-white mb-3 align-cent text-base md:text-xl font-semibold hover:bg-seventh border-amber-600 ${userAnswerResult[questionNo] == 2 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 2, answer[1].option_attraction,question,answer[1].option_title) }}>
                                {answer[1].option_title}
                            </Button>
                            <Button shape="round" className={`w-11/12 h-8 sm:h-9 bg-seventh text-white mb-3 align-cent text-base md:text-xl font-semibold hover:bg-seventh border-amber-600 ${userAnswerResult[questionNo] == 3 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 3, answer[2].option_attraction,question,answer[1].option_title) }}>
                                {answer[2].option_title}
                            </Button>
                            <Button shape="round" className={`w-11/12 h-8 sm:h-9 bg-seventh text-white mb-3 align-cent text-base md:text-xl font-semibold hover:bg-seventh border-amber-600 ${userAnswerResult[questionNo] == 4 ? "border-4" : ""}`} onClick={() => { nextQusetion(questionNo, 4, answer[3].option_attraction,question,answer[1].option_title) }}>
                                {answer[3].option_title}
                            </Button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};
export default QuizLayout2;
