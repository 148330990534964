
import React, { useState, useMemo, createContext, useCallback } from 'react';

import { AttractionData } from '../types/CustomType'

interface UserDisplayInfoType {
    quizDone: boolean;
    quizMapResult: AttractionData[];
    quizDoneFirstChecked: boolean;
    recordQuizUUID : string;
    recordQuizAnwser : string;
    recordQuizAttractions : string;
    //displayCollectPointCardDetail : string;
   
    setQuizDoneStatus: (quizDone: boolean) => void; // 做完測驗
    setQuizMapData: (attractions: AttractionData[]) => void; // 測驗完的結果
    setQuizDoneFirstCheckedStatus: (quizDoneFirstChecked: boolean) => void; //測驗完後第一次需滑出右側路徑介紹
    setRecordQuizUUID: (recordQuizUUID: string) => void; 
    setRecordQuizAnwser: (recordQuizAnwser: string) => void; 
    setRecordQuizAttractions: (recordQuizAttractions: string) => void; 
    //setDisplayCollectPointCardDetail: (displayCollectPointCardDetail: string) => void; 
    
}

export const UserInfoContext = React.createContext<UserDisplayInfoType>({
    quizDone: false,
    quizMapResult: [],
    quizDoneFirstChecked: false,
    recordQuizUUID : "",
    recordQuizAnwser : "",
    recordQuizAttractions : "",
    //displayCollectPointCardDetail : "",
   
    setQuizDoneStatus: () => { console.log('Set quizDone status'); },
    setQuizMapData: () => { console.log('Set quiz result map data'); },
    setQuizDoneFirstCheckedStatus: () => { console.log('Set quiz done checked '); },
    setRecordQuizUUID: () => { console.log('Set quiz done checked '); },
    setRecordQuizAnwser:() => { console.log('Set quiz done checked '); },
    setRecordQuizAttractions:() => { console.log('Set quiz done checked '); },
    //setDisplayCollectPointCardDetail: () => { console.log('Set Display Collect Point Card Detail'); },
   
})

export const UserContextProvider = (props: React.PropsWithChildren) => {

    const [quizDone, setQuizDone] = useState<boolean>(false); //是否做完測驗
    const [quizMapResult, setQuizMapResult] = useState<AttractionData[]>([]); //測驗結果
    const [quizDoneFirstChecked, setQuizDoneFirstChecked] = useState<boolean>(false); //是否做完測驗

    const [recordQuizUUID, setRecordQuizUUID] = useState<string>(""); 
    const [recordQuizAnwser, setRecordQuizAnwser] = useState<string>("");
    const [recordQuizAttractions, setRecordQuizAttractions] = useState<string>("");

    const [displayCollectPointCardDetail, setDisplayCollectPointCardDetail] = useState<string>(""); //是否做完測驗
    

    const setQuizDoneStatus = (quizDone: boolean) => {
        setQuizDone(quizDone);
    }

    const setQuizMapData = (attractions: AttractionData[]) => {
        setQuizMapResult(attractions);
    }

    const setQuizDoneFirstCheckedStatus = (quizDone: boolean) => {
        setQuizDoneFirstChecked(quizDone);
    }

    const setRecordQuizUUID_ = (recordQuizUUID: string) => {
        setRecordQuizUUID(recordQuizUUID);
    }


    const setRecordQuizAnwser_ = (recordQuizAnwser: string) => {
        setRecordQuizAnwser(recordQuizAnwser);
    }

    const setRecordQuizAttractions_ = (recordQuizAttractions: string) => {
        setRecordQuizAttractions(recordQuizAttractions);
    }

    const setDisplayCollectPointCardDetail_ = (recordQuizAttractions: string) => {
        setDisplayCollectPointCardDetail(recordQuizAttractions);
    }


    const value = useMemo(
        () => ({
            quizDone: quizDone,
            quizMapResult: quizMapResult,
            quizDoneFirstChecked: quizDoneFirstChecked,
            recordQuizUUID : recordQuizUUID,
            recordQuizAnwser : recordQuizAnwser,
            recordQuizAttractions : recordQuizAttractions,
            //displayCollectPointCardDetail : displayCollectPointCardDetail,
            setQuizDoneStatus: setQuizDoneStatus,
            setQuizMapData: setQuizMapData,
            setQuizDoneFirstCheckedStatus: setQuizDoneFirstCheckedStatus,
            setRecordQuizUUID:setRecordQuizUUID_,
            setRecordQuizAnwser:setRecordQuizAnwser_,
            setRecordQuizAttractions:setRecordQuizAttractions_,
            //setDisplayCollectPointCardDetail:setDisplayCollectPointCardDetail_

        }),
        [quizDone, quizMapResult, quizDoneFirstChecked,recordQuizUUID,recordQuizAnwser],
    );

    return <UserInfoContext.Provider value={value}>{(props.children)}</UserInfoContext.Provider>;

};
