import React, { useEffect, useState,useContext } from 'react';
import Router from './Router';
import './App.css';


function App() {


  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
