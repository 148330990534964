import React, { useEffect, useContext, useState } from 'react';
import collectionCard1 from '../../assets/images/collection4.jpg';
import AuthContext from '../../context/authContext';
import {PublicLayaltyCardData,MyVoucherData } from '../../types/CustomType';

import { USER_INFO_KEY, YJ_ACCESS_TOKEN,IS_USER_LOGIN } from '../../configuration';
import { useNavigate } from 'react-router-dom';

import '../style/collectPointCardsDetailStyle.css'
import { UserInfoContext } from '../../context/userInfoContext';
import { useParams } from 'react-router-dom';
import { ORIGINAL_URL } from '../../configuration';
import { sendQRcode } from '../../apis/LoyaltyCardService';
import { sendCoupon } from '../../apis/CouponService';

import { Image, Button, Divider,Modal } from 'antd';

type RouteParams = {
    id: string | undefined;
    qrcode: string | undefined;
};

const CollectPointCardsDetail = () => {


    const navigate = useNavigate();
    const {id,qrcode} = useParams<RouteParams>();

    const authCtx = useContext(AuthContext);
    const userContext = useContext(UserInfoContext);

    // Modal
    const [openModal, setOpenModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);

    
    const detectQRCodeEvent = async (uuid:string,value: any)  => {
        // api call 法以後再優化
        try {
            const updatePayload = {
                "answer": value, //QRCode編號
                "loyalty_card_uuid": uuid,
            };

            await sendQRcode(updatePayload)
            .then((response) => {
                const data = response?.data.message;
                //console.log("[qrcode] success response: ", data);
                setModalText(data);
                showModal();
            })
            .catch((error) => {
                //console.log("[qrcode] error response: ", error);
                // const data = error?.data.response.data;
                

                if (error.response) {
                    
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);

                    const jsonData = JSON.parse(error.response.data);

                    setModalText(jsonData.message);
                    showModal();
                } else if (error.request) {
                    
                    //console.log(error.request);
                    setModalText("目前伺服器維護中，請稍等");
                    showModal();
                } else {
                    
                    //console.log('Error', error.message);
                    setModalText("目前伺服器維護中，請稍等");
                    showModal();
                }


            });
            // authCtx.setUpdateInfo(false);
            authCtx.updateUserInfo();
           
        }
        catch (error) {
            //console.log('post qr code error:', error);
        }
    };


    // 兌換 API 狀態
    const [loading, setLoading] = useState(false)

    // 處理 兌換 API
    async function handleLogin(values: any) {
      setLoading(true)
      try {
        const updatePayload = {
            "voucher_uuid": values, //QRCode編號
        };
        await sendCoupon(updatePayload)
            .then((response) => {
                setLoading(false)
                const data = response?.data.message;
                //console.log("[兌換卷] success response: ", data);
                setModalText('恭喜兌換成功');
                showModal();
            })
        .catch((error) => {
            //console.log("[兌換卷] error response: ", error);
            setLoading(false)
            const data = error?.message;
            setModalText('提示! 兌換失敗');
            showModal();
        });
      } finally {
        setLoading(false)
      }
    }

    // 看行程
    const showItierary = (uuid:string,itinerary_name:string) => {
        navigate("/mobile/itinerary/"+uuid+"/"+itinerary_name);
    };

    // Modal
    const showModal = () => {
        setOpenModal(true);
    };

    const handleOk = () => {
        // setModalText('恭喜!搜集到了一個點數!');
        setConfirmLoading(true);
        authCtx.updateUserInfo();
        // TODO remove setTimeout and post code to server.
        setTimeout(() => {
            setOpenModal(false);
            setConfirmLoading(false);
        }, 1500);
    };



    useEffect(() => {

        // console.log("[CollectPointCardDetail] 進入點: "+location.pathname);
        // console.log("[CollectPointCardDetail] authCtx.isUpdateInfo: "+authCtx.isUpdateInfo);
        // console.log("[CollectPointCardDetail] qrcode: "+qrcode);
        // console.log("[CollectPointCardDetail] id: "+id);

        localStorage.setItem(ORIGINAL_URL, location.pathname);

        if(id != undefined && authCtx.isMobileLogin && authCtx.isUpdateInfo){
            // authCtx.setUpdateInfo(false);
            // authCtx.updateUserInfo();
            // userContext.setDisplayCollectPointCardDetail(true);
            

            if(qrcode!= undefined && qrcode!= null && qrcode!= "default"){
                console.log("我要看 uuid : "+authCtx.userInfo?.publicLayaltyCards[parseInt(id, 10)]);
                detectQRCodeEvent(authCtx.userInfo?.publicLayaltyCards[parseInt(id, 10)].uuid,qrcode);
            }
           
        } else {
            // localStorage.setItem(ORIGINAL_URL, location.pathname)
            authCtx.setUpdateInfo(false);
            authCtx.updateUserInfo();
        }

    }, [id,authCtx.isUpdateInfo]);


    const getStatus = (id:string) => {

        // console.log("兌換卷狀態:"+authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.status+" "+(authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.reward_limit - authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.current_reward_count));

        if(authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.status == "未兌換") {
            return false;
        }
        else {

            return true;
            
        }
    };

    return (        
        <>
            <Modal
                title="系統訊息"
                open={openModal}
                onOk={handleOk}
                confirmLoading={confirmLoading}  
                onCancel={handleOk}   
                footer={[
                    <Button 
                        key="submit" 
                        onClick={handleOk} 
                        className=' bg-primary text-white'
                        loading={confirmLoading}
                    >
                        確認
                    </Button>
                ]}
                // onCancel={handleCancel}
            >
                <p>{modalText}</p>
            </Modal>
            
            { (authCtx.isMobileLogin && id != undefined && authCtx.isUpdateInfo) && <div className='h-full w-full  flex-col '>
                <div className='flex w-full h-40 sm:h-60 bg-blue-600'>
                    <img
                        className='object-cover h-40 sm:h-60 w-full '
                        src={authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.voucher_image}
                    />
                </div>
                <div className='flex h-40 sm:h-60  w-full shadow-md rounded-lg relative'>
                    <img
                        className='object-cover h-40 sm:h-60 w-full '
                        src={authCtx.userInfo?.publicLayaltyCards[parseInt(id, 10)]?.loyalty_card_image}
                    />
                    <div className='flex absolute justify-left items-left pr-20 py-10 h-full '>
                        <div className=' grid gap-2  grid-cols-3 w-56 pl-10 justify-items-left'>
                            {
                                authCtx.userInfo?.publicLayaltyCards[parseInt(id, 10)]?.detail_info.map((value, index_) => (
                                    <div key={index_} className='h-7 w-7 my-2 sm:h-10 sm:w-10 sm:mx-6'>
                                        <img key={'img'+index_} className="object-fill h-full w-full"
                                            src= {value ? authCtx.userInfo?.publicLayaltyCards[parseInt(id, 10)].stamp_icon : authCtx.userInfo?.publicLayaltyCards[parseInt(id, 10)].un_stamp_icon}
                                        />
                                    </div>         
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className=' bg-white text-left p-2 rounded-lg shadow-md'>
                    <div className='py-1'>
                        <Button className='text-white font-semibold bg-primary w-full mt-auto'   onClick={() => showItierary(authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.itinerary_uuid,authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.itinerary_name)}> 查看活動路線 </Button> 
                    </div>  
                    <div className='py-1'>
                        <div className=' font-extrabold'>
                            兌換時間:
                        </div>
                        <div className='pl-4'>
                            {/* {`${authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.start_time}~${authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.exp_time} 止`} */}
                            {`${authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.description}`}
                        </div>
                    </div>
                    <div className='py-1'>
                        <div className=' font-extrabold'>
                            兌換地點:
                        </div>
                        <div className='pl-4'>
                            {authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.address} 「兌換禮限量 <b>{authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.reward_limit}</b> 份，目前已兌現 <b>{authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.current_reward_count}</b> 份，換完為止。」
                        </div>
                    </div>
                    <div className='py-1'>
                        <div className='font-bold'>
                            <div>
                                使用方式和注意事項:
                                {authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.warning.split("\n").map((context, index) => (
                                    <li key={index} className="liNotBold">{context}</li>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='py-1'>

                    {
                        
                                        
                        authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.reward_limit - authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.current_reward_count>0  ?

                        <Button className='text-white font-semibold bg-primary w-full mt-auto' disabled={getStatus(id)} loading={loading} onClick={() => handleLogin(authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.uuid)}>  
                            確認兌換
                        </Button> 
                        :
                        <Button className='text-white font-semibold bg-primary w-full mt-auto' disabled={true} loading={loading} onClick={() => handleLogin(authCtx.userInfo?.defaultVouchers[parseInt(id, 10)]?.uuid)}>  
                            確認兌換 (兌換禮已全數兌完畢)
                        </Button> 
                    }    
                      
                        
                    </div>        
                </div>
            </div>}      
        </>
    )
}


export default CollectPointCardsDetail;