import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Steps, theme, Popover } from 'antd';
const { Step } = Steps;
import type { StepsProps } from 'antd';
import MobileLayout from '../../layouts/MobileLayout';
import Quizlayout1 from '../../components/Quizlayout1';
import Quizlayout2 from '../../components/Quizlayout2';
import Quizlayout3 from '../../components/Quizlayout3';
import './style.css';
import QuizlayoutEnd from '../../components/QuizlayoutEnd';
import { AttractionData } from '../../types/CustomType';

// API
import { useQuery } from 'react-query';
import { QuizsData, TopicsData } from '../../types/CustomType';
import QuizService from '../../apis/QuizService';
import { title } from 'process';

//處理結果
import { MapInfoContext } from '../../context/mapInfoContext';
import { UserInfoContext } from '../../context/userInfoContext';
import { ProcessMapData, ResetMapData } from '../../lib/MapLib';

//路由器
import { ORIGINAL_URL,LINE_CHANNEL_ID,LINE_REDIRECT_URI  } from '../../configuration';
import AuthContext from '../../context/authContext';

interface QuizData {
  question: string;
  answer: string[];
}

interface StepData {
  title: string;
  content: any;
}

interface TopisData {
  question: string;
  image_slug: string;
  answer: AnswerOptionData[];
}

interface AnswerOptionData {
  option_title: string;
  option_attraction: AttractionData;
  option_icon: string;
}

const QuizStepPage = () => {

  const { token } = theme.useToken();
  const [current, setCurrent] = useState({ num: 0 });
  const [quizData, setQuizData] = useState<QuizData[]>([]);//useState<QuizData[]>(initialQuizData)
  const [disabledSteps, setDisabledSteps] = useState<boolean[]>([]);
  const [userAnswerResult, setUserAnswerResult] = useState<number[]>([]);

  const [steps, setStep] = useState<StepData[]>([]);

  const [quizsUUID, setQuizsUUID] = useState<string>("75ed8293-6959-4bd0-a22d-3b5e0dc64c41"); //8e8ffc5c-fa47-43e7-b7ae-90ce8c91bb0b(測試用) //75ed8293-6959-4bd0-a22d-3b5e0dc64c41(正式)

  const [resultPath, setResultPath] = useState<AttractionData[]>([]); //最後景點路徑

  const mapContext = useContext(MapInfoContext);
  const userContext = useContext(UserInfoContext);
  const [recordAnswer, setRecordAnswer] = useState<string>(""); //預設

  const [isDone, setIsDone] = useState<boolean>(false); 

  const authCtx = useContext(AuthContext);
  
  const { data, status } = useQuery<TopicsData[], Error>(
    `topis-${quizsUUID}`,
    async () => await QuizService.getTopicsData("private", quizsUUID),
    {

      onSuccess: (res) => {
        setIsDone(false);
        const tmpStep: (StepData)[] = [];
        const tmpOption: (TopisData)[] = [];
        let tmpAnswer: (AnswerOptionData)[] = [];

        userContext.recordQuizUUID = quizsUUID;
        //console.log("res ="+res);
        for (let i = 0; i < res.length; i++) {
          for (let j = 0; j < res[i].quizs.length; j++) {

            tmpAnswer.push({ option_title: res[i].quizs[j].title, option_attraction: res[i].quizs[j].option_attraction, option_icon: res[i].quizs[j].icon_type });
          }
          tmpOption.push({ question: res[i].title, image_slug: res[i].image_slug, answer: tmpAnswer });
          tmpAnswer = [];
        }

        for (let i = 0; i < res.length; i++) {
          
          switch (res[i].type_id) {
            case "2":
              tmpStep.push({ title: '', content: <Quizlayout1 nextQusetion={nextQusetion} question={tmpOption[i].question} answer={tmpOption[i].answer} questionNo={i} userAnswerResult={userAnswerResult} /> })
              break;
            case "1":
              tmpStep.push({ title: '', content: <Quizlayout2 nextQusetion={nextQusetion} question={tmpOption[i].question} image_slug={tmpOption[i].image_slug} answer={tmpOption[i].answer} questionNo={i} userAnswerResult={userAnswerResult} /> })
              break;
            case "3":
              tmpStep.push({ title: '', content: <Quizlayout3 nextQusetion={nextQusetion} question={tmpOption[i].question} answer={tmpOption[i].answer} questionNo={i} userAnswerResult={userAnswerResult} /> })
              break;
          }
        }

        // tmpStep.push({ title: '', content: <QuizlayoutEnd /> })

        setStep(tmpStep);
        const tmpDisabledSteps = Array(steps.length).fill(true);
        tmpDisabledSteps[0] = false;
        setDisabledSteps(tmpDisabledSteps);
        setUserAnswerResult(Array(tmpStep.length - 1).fill(0));
        // ResetMapData(mapContext);
        

      },

    }
  );

  const appendText = (currentAnswer:string) => {
    userContext.recordQuizAnwser  = userContext.recordQuizAnwser + currentAnswer;
  };

  const nextQusetion = (questionNo: number, userAnswer: number, userAnswerattraction: AttractionData,questionTitle:string,questionAnswer:string) => {
    // console.log("[nextQusetion] = questionTitle:"+questionTitle+" questionAnswer:"+questionAnswer+" AttractionData:"+userAnswerattraction)
    appendText(questionTitle+" "+questionAnswer+'\n');
    const newDisabledSteps = disabledSteps;
    const newUserAnswerResult = userAnswerResult;
    newDisabledSteps[questionNo] = false;
    setDisabledSteps(newDisabledSteps);
    newUserAnswerResult[questionNo] = userAnswer;
    setUserAnswerResult(newUserAnswerResult);
    // userAnswerResult[questionNo] = userAnswer;
    // setUserAnswerResult(userAnswerResult);
    if (userAnswerattraction != null) {
      userAnswerattraction.order = questionNo;
      // console.log("你選擇了景點順序是 :" + userAnswerattraction.order);
      setResultPath(resultPath => [...resultPath, userAnswerattraction]);
    }
    if (steps.length == current.num + 1) {
      newDisabledSteps[questionNo + 1] = false;
      setDisabledSteps(newDisabledSteps);
      
    }
    if(steps.length <= disabledSteps.length){

      

      setCurrent({
        num: (questionNo + 1)
      });
    }
  };

 const attractionUUIDs: { uuid: string; }[] = [];


 const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

  useEffect(() => {

    localStorage.setItem(ORIGINAL_URL, location.pathname)

    
    if(authCtx.isMobileLogin){
      if (steps.length == current.num && steps.length !=0) {

        for (let i = 0; i < resultPath.length; i++) {
          const attractionElement = { uuid : resultPath[i].uuid};
          attractionUUIDs.push(attractionElement);
        }

        
  
        const tmp = JSON.stringify(attractionUUIDs);
        //console.log(tmp.toString())
        userContext.setRecordQuizUUID(quizsUUID);
        userContext.setRecordQuizAnwser(userContext.recordQuizAnwser);
        userContext.setRecordQuizAttractions(tmp.toString());
  
        ProcessMapData(mapContext, resultPath, true);
        userContext.setQuizMapData(resultPath);
        userContext.setQuizDoneStatus(true);
        userContext.setQuizDoneFirstCheckedStatus(true);
        
        setIsDone(true);
      }
    } else {
      
      // authCtx.updateUserInfo();
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
    }
    
  }, [authCtx.isMobileLogin,current]);

  

  


  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    height: "100%",
    width: "100%"
  };

  const onChange = (value: number) => {
    if( disabledSteps[0]==false && userAnswerResult[0]>0 ){
      if( userAnswerResult[value]>0 || disabledSteps.length == value ){
        setCurrent({
          num: (value)
        });
      }
    }
  };


  return (

    <MobileLayout>

      {status === "loading" && <p>載入資料中...</p>}
      {status === "success" && (
        <>
          <div className='bg-white flex-1 w-full p-4 '>
            <div className='h-full'>
              {steps.length > 0 &&  steps.length != current.num && <div style={contentStyle}>{steps[current.num].content}</div>}
              { isDone && <QuizlayoutEnd /> }
            </div>
          </div>
          <div className='bg-white flex flex-col justify-start px-4 py-4'>
            {steps.length > 0 && disabledSteps.length < steps.length && 
              <Steps current={current.num} responsive={false} className='mb-6' size="small" onChange={onChange} >
                {steps.map((step, index) => (
                  <Step
                    key={index}
                    title={step.title}
                    disabled={disabledSteps[index]}
                  />
                ))}
              </Steps>
            }
            <Link to={'/mobile'} className=' bg-primary text-white w-1/3 rounded-2xl px-1 py-2 ' >
              回永靖大地圖
            </Link>
          </div>
        </>
      )}



    </MobileLayout>
  );
};
export default QuizStepPage;
