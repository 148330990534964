import React, { useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';
import quizIndex from '../assets/images/quiz/quiz_index_2.webp'
import quizSuggestedRoute from '../assets/images/quiz/quiz_suggested_route.webp'
import quizEndAgain from '../assets/images/quiz/quiz_end_again.webp'
import AuthContext from '../context/authContext'; 





const QuizlayoutEnd = () => {

    const authCtx = useContext(AuthContext);
    
    // const getAvatar = ((loggedIn: boolean) => {
    //     const storageUserInfo = localStorage.getItem(USER_INFO_KEY);
    //     const userInfo = storageUserInfo ? JSON.parse(storageUserInfo) : null;
    //     if (userInfo?.profilePicture && loggedIn) {
    //       return <Avatar src={userInfo?.profilePicture} alt="Icon" />;
    //     } else {
    //       return <UserOutlined className='text-white' />;
    //       // return (
    //       //   <div className=' px-2' onClick={() => setIsLineLoginOpen(true)}>
    //       //     登入
    //       //   </div>
    //       // )
    //     }
    //   })

    return (
        <>
            <div className='flex flex-col justify-center items-center bg-white p-1 sm:p-2 flex-1  ' >
                <div className='flex pb-0 sm:py-4 px-4 text-lg sm:text-2xl text-sixth font-extrabold '>
                    <h2>測驗完成</h2>
                </div>
                <div className='flex pt-0 sm:pt-4 px-3 min-h-8 text-base sm:text-2xl text-sixth text-left overflow-y-auto break-words font-extrabold'>
                    從 {authCtx.userInfo?.nick_name} 開始你的小旅行吧！
                </div>
                <div className='flex pb-0 sm:pb-2 px-3 min-h-8 text-sm sm:text-xl text-sixth text-left overflow-y-auto break-words'>
                    終於放假了，你決定來場鄉村度假，離開大都市到永靖來場慢步調旅遊，期待是一個放鬆愜意的假期，你決定和誰一起前往?
                </div>
            </div >
            <div className='flex justify-center items-center bg-white p-2 flex-1  '>
                <Image
                    preview={false}
                    className='object-contain max-w-[400px] max-h-[280px] sm:max-w-[400px] sm:max-h-[280px]'
                    src={quizIndex}
                />
            </div>
            <div className='flex flex-col justify-center items-center text-xl bg-white text-fourth  flex-1 text-left overflow-y-auto overflow-hidden '>
                <div className='flex justify-around items-center w-full px-2'>
                    <div className='flex flex-col justify-center items-center'>
                        <Link to={'/mobile'} className='p-2 rounded-2x flex flex-col justify-center items-center'>
                        {/* <Link to={'/mobile?mode=customized'} className='p-2 rounded-2x flex flex-col justify-center items-center'> */}
                            <Image src={quizSuggestedRoute} preview={false} className='max-h-[60px] max-w-[60px] sm:max-h-[75px] sm:max-w-[75px]' />
                            <div className='pt-2 text-xl text-primary font-extrabold'>查看建議路線</div>
                        </Link>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <Link to={'/mobile/quiz'} className='p-2 rounded-2x flex flex-col justify-center items-center'>
                            <Image src={quizEndAgain} preview={false} className='max-h-[60px] max-w-[60px] sm:max-h-[75px] sm:max-w-[75px]' />
                            <div className='pt-2 text-xl text-primary font-extrabold'>再玩一次</div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};
export default QuizlayoutEnd;
