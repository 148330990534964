import React, { useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { Image, Button } from 'antd';
import MobileLayout from '../../layouts/MobileLayout';
// import quizIndex from '../../assets/images/quiz/quiz_index.png';

// API
import { useQuery } from 'react-query';
import { QuizsData } from '../../types/CustomType';
import QuizService from '../../apis/QuizService';
import { ORIGINAL_URL,LINE_CHANNEL_ID,LINE_REDIRECT_URI  } from '../../configuration';
import AuthContext from '../../context/authContext';

const QuizPage = () => {

  
  const [quizsUUID, setQuizsUUID] = useState<string>("75ed8293-6959-4bd0-a22d-3b5e0dc64c41"); //8e8ffc5c-fa47-43e7-b7ae-90ce8c91bb0b(測試用) //75ed8293-6959-4bd0-a22d-3b5e0dc64c41

  const authCtx = useContext(AuthContext);


  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };


  useEffect(() => { 

      localStorage.setItem(ORIGINAL_URL, location.pathname)

      if(authCtx.isMobileLogin){
        getQuizs();
      } else {
        //console.log("[CollectPointCards] 進入點"+location.pathname)
        window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
        // authCtx.updateUserInfo();
      }
  }, [authCtx.isMobileLogin]);

  const { refetch: getQuizs ,data, status } = useQuery<QuizsData, Error>(
    `quiz-${quizsUUID}`,
    async () => await QuizService.getQuizData("private", quizsUUID),
    {
      enabled: false,
      onSuccess: (res) => {
        //console.log("有變化:"+res.title);
      },

    }
  );

  return (
    <>
      { (authCtx.isMobileLogin ) &&
         <MobileLayout>
         {status === "loading" && <p>載入資料中...</p>}
         {status === "success" && (
           <div className='text-center w-full flex-grow flex flex-col relative bg-sixth'>
             <div className='flex justify-center items-center p-4 flex-1'>
               <Image
                 preview={false}
                 className='object-contain max-w-[190px] max-h-[190px] sm:max-w-[280px] sm:max-h-[280px] '
                 src={data?.typical_image}
               />
             </div>
             <div className='flex justify-center items-center text-lg sm:text-xl text-seventh font-black flex-1 text-left overflow-hidden '>
               <div className='py-4 px-6 sm:py-6 sm:px-8 max-h-[300px] min-h-[150px] '>
                 {
                   data?.title
                 }
               </div>
             </div>
             <div className=' flex flex-col justify-center items-center flex-1 '>
               <div className='max-h-[270px] min-h-[140px] w-full flex flex-col justify-center items-center'>
                 <div className=' text-xl text-primary font-bold w-full'>
                   客製化你的專屬路線
                 </div>
                 <div className='flex justify-center items-center w-full'>
                   <Link to={'/mobile/quiz/step'} className='w-full h-full' >
                     <Button shape="round" className=' bg-primary text-white w-3/5 my-4 h-14 text-2xl'>
                       開始測驗
                     </Button>
                   </Link>
                 </div>
               </div>
             </div>
             <div className='h-10 bg-white flex justify-start items-center px-4 py-6'>
               <Link to={'/mobile'} className=' bg-primary text-white w-1/3 rounded-2xl px-1 py-2' >
                 回永靖大地圖
               </Link>
             </div>
           </div>
         )}
       </MobileLayout>
      }
      
    </>
    
  );
};
export default QuizPage;
