import React, { useEffect, useState, useContext, useRef } from 'react';
import liff from '@line/liff';
import { Button } from 'antd';
import { ORIGINAL_URL } from '../configuration';

//掃描器
import QRScannerV2 from '../components/QRScannerV2';


const ScanLiffCodeComponent = () => {

    const [isQrModalOpen, setQrModalOpen] = useState(true);


    return (
        <>
           <QRScannerV2
                isVisible={isQrModalOpen}
                title="請掃描 QR Code 圖片"
                onClose={() => setQrModalOpen(false)}
            >
            </QRScannerV2>
         </>  
    );
};
export default ScanLiffCodeComponent;