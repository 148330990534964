import React, { useContext, useEffect } from 'react';
import AuthContext from '../../../context/authContext';
import { Button, Dropdown, Layout, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import './styles.css';
import { useNavigate, Link } from 'react-router-dom';
import { USER_INFO_KEY } from '../../../configuration';
import logo from '../../../assets/images/logo-white-s-1.png';
const AntHeader = Layout.Header;

// import { USER_INFO_KEY } from '../../../utils/axiosManager';
// import PlayerPage from '../pages/PlayerPage';

// const DropDownMenuItem = (props: { name: string; imgSrc: any; imgAlt: string; url: string }) => {
//   return (
//     <Link to={props.url} className='w-full flex justify-center items-center my-2'>
//       <img className='w-1/6 mx-2  h-2/3' src={props.imgSrc} alt={props.imgAlt} />
//       <span className='w-3/4 pl-2 text-white'>{props.name}</span>
//     </Link>
//   );
// };

export interface ProfileData {
  nickName: string;
  profilePicture: string;
}


const loggedIn = ((authCtx: any) => {
  // console.log("loggedIn-userInfo", authCtx);
  // TODO 還要驗證 token 是否過期
  if (authCtx && authCtx.isLogin == true) {
    return true;
  } else {
    return false
  }
})

const getAvatar = ((loggedIn: boolean) => {
  const storageUserInfo = localStorage.getItem(USER_INFO_KEY);
  const userInfo = storageUserInfo ? JSON.parse(storageUserInfo) : null;
  if (userInfo?.profilePicture && loggedIn) {
    return <Avatar src={userInfo?.profilePicture} size="large" alt="Icon" />;
  } else {
    return <UserOutlined />;
  }
})



const Header: React.FC = (props) => {
  const guestItems: MenuProps['items'] = [//訪客
    {
      key: '1',
      label: (
        <Link to={'/login'} className='w-full flex justify-center items-center my-2'>
          <span><UserOutlined /></span>
          <span className='w-3/4 pl-2 text-stone-600'>登入</span>
        </Link>
      ),
    }
  ]

  const memberItems: MenuProps['items'] = [//會員
    {
      key: '1',
      label: (
        <Link to={'/profile/edit'} className='w-full flex justify-center items-center my-2'>
          <span><UserOutlined /></span>
          <span className='w-3/4 pl-2 text-stone-600'>會員資料</span>
        </Link>
      ),
    }, {
      key: '2',
      label: (
        <Link to={'/'} className='w-full flex justify-center items-center my-2' onClick={() => { handleLogout() }}>
          <span><UserOutlined /></span>
          <span className='w-3/4 pl-2 text-stone-600'>登出</span>
        </Link>
      ),
    }
  ]
  const {
    ...otherProps
  } = props;
  const authCtx = useContext(AuthContext);
  // const userInfo = authCtx;
  const navigate = useNavigate();
  // const userData = JSON.parse(localStorage.getItem(USER_INFO_KEY)|| '{}');
  // const userData = userInfo;
  // const userName = userInfo.mail.split('@')[0];
  const handleLogout = () => {
    authCtx.logout();
  }
  return (
    <AntHeader style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '100%',
      height: '100px'
    }} className=' bg-slate-400 flex justify-between items-center'>
      <Link to={'/'} className='flex justify-center'>
        <img src={logo} className='w-[300px] h-[100px] max-h-[118px]' alt="" />
      </Link>
      <Dropdown menu={{ items: loggedIn(authCtx) ? memberItems : guestItems }} placement="bottomLeft" arrow>
        <Button shape="circle" type='text' className='flex justify-center items-center'>
          {getAvatar(loggedIn(authCtx))}
        </Button>
      </Dropdown>
    </AntHeader>
  );
};

export default Header;
