import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import { useQuery } from "react-query";
import Tag from '../types/Tag';
import TagService from '../apis/TagService';

interface AllTagsDrawerProps {
    onClose: () => void;
    // openCollapse: (title: string, view: string) => void;
    openCollapse: (title: string, view: string, tagID: string) => void;
    setTagID: (ID: string) => void;
    onAllInineraryDrawer : () => void;
}

const AllTagsDrawer: React.FC<AllTagsDrawerProps> = ({ onClose, openCollapse , setTagID,onAllInineraryDrawer}) => {

    const [tags, setTags] = useState<Tag[]>([]);

    const handlerOpenCollapse = ((title: string) => {
        onClose();
        // openCollapse(`關鍵字瀏覽: #${title}`, 'allTagsCollapse');
        
    })

    const { isLoading, isSuccess, isError, data } = useQuery<any, Error>(
        "query-default-tags",
        async () => {
            return await TagService.findDefault();
        },
        {
            keepPreviousData: true,
            onSuccess: (res) => {
                setTags(res.message);
            },
            onError: (err: any) => {
                console.log(err);
            }
        }
    );

    return (
        <div>
            <div className='flex flex-wrap justify-start w-full pr-2'>
                {isSuccess && tags.map((tag, index) => (
                    <Button shape="round" className=' text-white bg-secondary m-1 hover:bg-primary hover:text-white' key={index}
                        onClick={() => {
                            // handlerOpenCollapse(`${tag.name}`);
                            openCollapse(`關鍵字瀏覽: #${tag.name}`, 'allTagsCollapse', tag.id);
                            setTagID(tag.id);
                            onClose();
                        }}>
                        #{tag.name}
                    </Button>
                ))}
            </div>
            <div className='flex flex-col justify-end items-end font-bold text-primary pt-10'>
                <div className='text-base'>
                    <span className='text-slate-700'>猶豫不決?</span>
                    <Link to={'/mobile/quiz'} className='text-primary' >
                        或是做個小測驗
                    </Link>
                    <FontAwesomeIcon icon={faLongArrowAltRight} className='pl-2 ' />
                </div>
                <div className=' text-base'>
                    <span className=' text-slate-700'>或者看看我們的</span>
                    <span onClick={() => { onAllInineraryDrawer() }}>經典行程</span>  {/* TODO: 導到經典行程頁面 */}
                </div>
            </div>
        </div>
    );
};

export default AllTagsDrawer;
