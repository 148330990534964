
import React, { useState, useMemo, createContext, useCallback } from 'react';

import {MarkerType,AttractionData,LocationType} from '../types/CustomType'

interface MapDisplayInfoType {
    attraction : AttractionData[]; 
    markers: MarkerType[];
    isDrawPath: boolean;
    isDrawOrder : boolean;
    myLocation : LocationType;
    zoom : number;
    isUpdate : boolean;
    setInMarkers : (markers: MarkerType[]) => void;
    setInAttractions : (attractions: AttractionData[]) => void;
    setInDrawPath : (isDrawPath: boolean) => void;
    setInMyLocation : (lat : number,lng : number) => void;
    setMapZoom: (zoom : number) => void;
    setInIsUpdate: (isUpdate: boolean) => void;
}

// 建立一個 Context
export const MapInfoContext = React.createContext<MapDisplayInfoType>({
    attraction : [],
    markers: [],
    isDrawOrder : false,
    isDrawPath: false,
    myLocation : ({lat:0,lng:0}),
    zoom : 0,
    isUpdate: false,
    setInMarkers : ()=>{console.log('Set mobile status');},
    setInAttractions : () => { console.log('Set mobile status'); },
    setInDrawPath: () => { console.log('Set mobile status'); },
    setInMyLocation: () => { console.log('Set my location status'); },
    setMapZoom: () => { console.log('Set my map Zoom'); },
    setInIsUpdate: () => { console.log('isUpdate'); }
})



export const MapContextProvider = (props: React.PropsWithChildren) => {
   
    const [myLocation, setMyLocation] = useState<LocationType>({lat:0,lng:0}); // 本身的位置
    const [markers, setMarkers] = useState<MarkerType[]>([]); // marker 陣列
    const [attractions, setAttractions] = useState<AttractionData[]>([]); // 景點陣列
    const [isDrawPath, setIsDrawPath] = useState<boolean>(false); //是否繪製路徑
    const [isDrawOrder, setIsDrawOrder] = useState<boolean>(false); //是否繪製順序
    const [zoom, setZoom] = useState<number>(0); //是否繪製順序
    const [isUpdate, setIsUpdate] = useState<boolean>(false); //是否更新完畢


    const setInAttractions = (attractions: AttractionData[]) => {
        // console.log("[mapInfoContext] attractions ="+attractions.length);
        setAttractions(attractions)
    }

    const setInMarkers = (markers: MarkerType[]) => {
        setMarkers(markers)
    }

    const setInIsDrawPath = (isDrawPath: boolean) => {
        setIsDrawPath(isDrawPath)
    }

    const setInIsDrawOrder = (isDrawOrder: boolean) => {
        setIsDrawOrder(isDrawOrder)
    }

    const setInMyLocation = (lat : number,lng : number) => {
        setMyLocation(({lat,lng}))
    }

    const setInIsUpdate = (isUpdate: boolean) => {
        setIsUpdate(isUpdate)
    }

    const setMapZoom = (zoom : number) => {
        setZoom(zoom)
    }

    const value = useMemo(
        () => ({
            attraction : attractions, 
            markers :markers ,
            isDrawPath : isDrawPath,
            isDrawOrder : isDrawOrder,
            myLocation : myLocation,
            zoom : zoom,
            isUpdate : isUpdate,
            setInMarkers : setInMarkers,
            setInDrawPath : setInIsDrawPath,
            setInAttractions : setInAttractions,
            setInMyLocation : setInMyLocation,
            setMapZoom : setMapZoom,
            setInIsUpdate : setInIsUpdate,
        }),
        [attractions,markers,isDrawPath,isDrawOrder,myLocation,zoom,isUpdate],
        
    );
    
    return <MapInfoContext.Provider value={value}>{(props.children)}</MapInfoContext.Provider>;

};
