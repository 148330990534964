import React, { useState, useMemo, createContext, useCallback } from 'react';
import { USER_INFO_KEY,IS_USER_LOGIN, YJ_ACCESS_TOKEN, IS_MOBILE_LOGIN,ORIGINAL_URL } from '../configuration'
import { LINE_LOGIN_URI, LINE_LOGIN_CLIENT_ID, LINE_REDIRECT_URL ,LINE_CHANNEL_ID,LINE_REDIRECT_URI,COOKIE_NAME,LINE_CHANNEL_SECRET,COOKIE_GET_LINETOKEN_NAME,COOKIE_LOGUT_NAME} from '../configuration'
import { getUserInfo } from '../apis/member'
import { useQuery, QueryCache } from 'react-query';
// new query user info
import { UserInfoData } from '../types/CustomType';
import UserService from '../apis/UserService';
import { useNavigate} from 'react-router-dom';

interface UserContextType {
  userInfo: UserInfoData;
  isLogin: boolean;
  isMobileLogin: boolean;
  isUpdateInfo:boolean;
  lineToken:string;
  updateUserInfo: () => void;
  setMobileLogin: (status: boolean) => void;
  setUserLogin: (status: boolean) => void;
  setUpdateInfo: (status: boolean) => void;
  setLineToken: (toke: string) => void;
  logout: () => void;
}

const AuthContext = createContext<UserContextType>({
  userInfo : {
    id : '',
    nick_name: '',
    email: '',
    profile_picture:'',
    phone:'',birthday:'',
    gender:'',
    address_city:'',
    address_township:'',
    tour_check:false,
    permission:'',
    defaultVouchers:[],
    notExchangeVouchers:[],
    exchangeVouchers:[],
    expiredVouchers:[],
    publicLayaltyCards:[],
    voucherCount:0,
  },
  lineToken:'',
  isLogin: false,
  isMobileLogin: false,
  isUpdateInfo:false,
  updateUserInfo: () => {console.log('No member information'); },
  setMobileLogin: () => { console.log('Set mobile status'); },
  setUserLogin: () => { console.log('Set login status'); },
  setUpdateInfo: () => { console.log('Set update info status'); },
  setLineToken: () => { console.log('Set line token'); },
  logout: () => { console.log('Sign out'); },
  
  
});

export const AuthContextProvider = (props: React.PropsWithChildren) => {

  // const navigate = useNavigate();

  let defaultUserInfo = {
    id : '',
    nick_name: '',
    email: '',
    profile_picture:'',
    phone:'',birthday:'',
    gender:'',
    address_city:'',
    address_township:'',
    tour_check:false,
    permission:'',
    defaultVouchers:[],
    notExchangeVouchers:[],
    exchangeVouchers:[],
    expiredVouchers:[],
    publicLayaltyCards:[],
    voucherCount:0,
  };

  //若已存在...
  const userInfoValue = localStorage.getItem(USER_INFO_KEY);
  const yjAccessTonken = localStorage.getItem(YJ_ACCESS_TOKEN);
  if (userInfoValue != 'undefined' && userInfoValue && yjAccessTonken) { 
    defaultUserInfo = JSON.parse(userInfoValue);
  }

  const mobileLoginLocalstorage = localStorage.getItem(IS_MOBILE_LOGIN);
  const userLoginLocalstorage = localStorage.getItem(IS_USER_LOGIN);
  //const [userInfo, setUserInfo] = useState<IUpdateUserInfo>(defaultUserInfo);

  const [userInfo, setUserInfo] = useState<UserInfoData>(defaultUserInfo);
  

  const [isLogin, setIsLogin] = useState<boolean>(
    userLoginLocalstorage ? JSON.parse(userLoginLocalstorage) : false
  );

  const [isMobileLogin, setIsMobileLogin] = useState<boolean>(
    mobileLoginLocalstorage ? JSON.parse(mobileLoginLocalstorage) : false
  );

  const [isUpdateInfo, setUpdateInfo] = useState<boolean>(false);
  const [lineToken, setLineToken] = useState<string>("");
 

  const logout = useCallback(async () => {
    localStorage.removeItem(USER_INFO_KEY);
    localStorage.removeItem(YJ_ACCESS_TOKEN);
    localStorage.removeItem(IS_MOBILE_LOGIN);
    localStorage.removeItem(IS_USER_LOGIN);
    setUserInfo(defaultUserInfo);
    // setUserLogin(false);
    setMobileLogin(false);
    localStorage.setItem(ORIGINAL_URL, "/mobile")
    // liff.logout();
    // console.log("[authContext] 我登出嚕");
  }, []);

  const setMobileLogin = (status: boolean) => {
    setIsMobileLogin(status)
    localStorage.setItem(IS_MOBILE_LOGIN, JSON.stringify(status));
  }

  const setUserLogin = (status: boolean) => {
    setIsLogin(status)
    localStorage.setItem(IS_USER_LOGIN, JSON.stringify(status));
  }

  const updateUserInfo = useCallback(async () => {

    queryUserInfoByAuth();

  }, [setUserInfo]);


  const setLineTokens = (token: string) => {
    setLineToken(token)
  }

  const { refetch: queryUserInfoByAuth } = useQuery<UserInfoData, Error>(
    `queryUserInfo`,
    async () => await UserService.getUserInfoData(),
    {
      enabled: false,
      onSuccess: (res) => {
        // console.log("更新資訊...."+res);
        if(res && res.id!=undefined){
          // console.log("更新資訊...."+JSON.stringify(res));
          // 儲存 user info
          localStorage.setItem(USER_INFO_KEY, JSON.stringify(res));
          setUserInfo(res);
          // 儲存 login 狀態
          localStorage.setItem(IS_USER_LOGIN, JSON.stringify(true));
          // setUserLogin(true);
          setUpdateInfo(true);
          setMobileLogin(true);

        }
        else {
          // localStorage.clear();
          window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=STATE&scope=openid%20profile%20email`;
        }

      },

      onError: (err: any) => {
        console.log(err);
        // localStorage.clear();
        window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=STATE&scope=openid%20profile%20email`;
      }
    }
  );


  const value = useMemo(
    () => ({
      userInfo: userInfo,
      isLogin : isLogin,
      isMobileLogin: isMobileLogin,
      isUpdateInfo: isUpdateInfo,
      lineToken:lineToken,
      updateUserInfo:updateUserInfo,
      setUserLogin : setUserLogin,
      setMobileLogin: setMobileLogin,
      setUpdateInfo: setUpdateInfo,
      logout: logout,
      setLineToken : setLineTokens,
    }),
    [userInfo, updateUserInfo, logout, isMobileLogin, setMobileLogin,isLogin, setUserLogin,isUpdateInfo,setUpdateInfo,lineToken,setLineToken],
  );

  return <AuthContext.Provider value={value}>{(props.children)}</AuthContext.Provider>;
};

export default AuthContext;
