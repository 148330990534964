import { post, get, getAuthHeader } from './base';
import { API_URL } from '../configuration';
import {ItineraryDetailType} from '../types/CustomType';

//搜尋路徑
/*
 (1). 搜尋所有
 (2). 標題
 (3). 標籤
*/
const findDefault = async () =>{ //search_mode: string|null,title: string|null,tags: any|null
    const response = await get(`${API_URL}/api/way?query_type=public`);
    return response.data;
}

const findAppoint = async (itinerary_id : string) : Promise<ItineraryDetailType> => { //search_mode: string|null,title: string|null,tags: any|null
    const response = await get(`${API_URL}/api/way?query_type=appoint&uuid=${itinerary_id}`);
    return response.data.message;
}

const ItineraryService = {
    findDefault,
    findAppoint,
}

export default ItineraryService;
