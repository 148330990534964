import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../context/authContext';
import { USER_INFO_KEY, YJ_ACCESS_TOKEN } from '../../configuration';
import { useNavigate, useLocation, Link, Outlet } from 'react-router-dom';
import { Form, Input, Button, Select, DatePicker, Checkbox, Modal, Avatar, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import MainLayout from '../../layouts/ManLayout';
import { getUserInfo, updateUserInfo } from '../../apis/member';
import { IUserInfo } from '../../types/Member';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faTicket, faGrip } from '@fortawesome/free-solid-svg-icons';
import MobileLayout from '../../layouts/MobileLayout';
import MemberProfile from '../../components/MobileProfile/MemberProfile';
import moment from 'moment';
import 'moment/locale/zh-tw';
import './style.css';

import { IS_MOBILE_LOGIN,ORIGINAL_URL,COOKIE_NAME,COOKIE_LOGUT_NAME,LINE_CHANNEL_ID,LINE_REDIRECT_URI } from '../../configuration'
moment.locale('zh-tw');

const { Title, Paragraph } = Typography;

interface IFormItem {
  nick_name: string;
  phone: string;
}

const MobileProfilePage = () => {

  const authCtx = useContext(AuthContext);

  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  useEffect(() => {
        
    
    localStorage.setItem(ORIGINAL_URL, location.pathname)

    if(authCtx.isMobileLogin){
      authCtx.updateUserInfo();
    } else {
      // 觸發 Line 登入
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
    }

  }, []);

  return (
    <>
     { (authCtx.isMobileLogin ) &&
                <>

<MobileLayout>
      
      <div className=' w-full h-full flex flex-col flex-1 px-4 pt-4 bg-slate-100  '>{/* flex-grow max-h-[844px] bg-slate-100 */}
        
       
        <div className=' h-[75vh] overflow-hidden overflow-y-auto  '> 
      
          <Outlet />
        </div>

       
        <footer className='flex w-full mt-auto mb-2 '> {/* sm:h-1/6 */}
          <div className='flex w-full h-full p-4 bg-white rounded-2xl shadow-md'>
            <Link
              to={'/mobile/profile/main'}
              className='h-full  text-center flex-1 flex justify-center items-center text-black '
            >
              <div>
                <FontAwesomeIcon icon={faAddressCard} style={{ color: "#179188", fontSize: "1.5rem" }} className='pb-1  ' />
                <div className='text-black'>會員資料</div>
              </div>
            </Link>
            <Link
              to={'/mobile/profile/collection'}
              className='h-full  text-center flex-1 flex justify-center items-center '
            >
              <div>
                <FontAwesomeIcon icon={faGrip} style={{ color: "#179188", fontSize: "1.5rem" }} className='pb-1 ' />
                <div className='text-black'>集點卡</div>
              </div>
            </Link>
          
          </div>
        </footer>
      </div>

    </MobileLayout>
                </>
    }
     
    </>
    
  );
};
export default MobileProfilePage;
