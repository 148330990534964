import { post, get, getAuthHeader } from './base';
import dayjs from 'dayjs';
import { API_URL } from '../configuration';

export const getYjTokenAndStatus = async (lineId: string, idToken: string) => {
    // console.log("login-lineId:", lineId);
    
    try {
        const config = {
            headers: { "Authorization": `Bearer ${idToken}` }
            // headers: { "X-Token": `${idToken}` }
        };

        // local
        // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FjY2Vzcy5saW5lLm1lIiwic3ViIjoiVThiMjE3M2NhNzFmYmMzMTRjOWNiZmEzYWU4NDk1Mjc5IiwiYXVkIjoiMjAwMDAyOTU1MyIsImV4cCI6MTY4ODc4OTI3NiwiaWF0IjoxNjg4Nzg1Njc2LCJhbXIiOlsibGluZXNzbyJdLCJuYW1lIjoiUGhvZW5peCIsInBpY3R1cmUiOiJodHRwczovL3Byb2ZpbGUubGluZS1zY2RuLm5ldC8waDVqcUpwMzZfYWtWTU0zX09neDhWRW5CMlpDZzdIV3dOTkZZbkpEdGtOM00wQkg0UmNRY2tkejB4TTNJeUNuaERlRkFzZDJzMk1pRTIifQ.IhLVQ7gfezOFqRs1d-9YIVOMuTtKQBktSNz8C1kPMSw";
        // const loginInfo = { 'token': token, 'isNewUser': true };

        // console.log('loginInfo:', loginInfo);
        // return loginInfo;

        // API
        return await get(`${API_URL}/api/login`, config);

    } catch (error) {
        console.log("getYjTokenAndStatus-api-error: ", error);
        //Log errors
    }
}

export const getUserInfo = async () => {
    try {

        //API
        const userInfo : any = await get(`${API_URL}/api/user`, getAuthHeader());

        // local
        // const userInfo = {
        //     id: 'abc-123',
        //     nick_name: 'Phxxxxx',
        //     email: 'phx@gmail.com',
        //     profile_picture: 'https://profile.line-scdn.net/0h5jqJp36_akVMM3_Ogx8VEnB2ZCg7HWwNNFYnJDtkN3M0BH4RcQckdz0xM3IyCnhDeFAsd2s2MiE2',
        //     phone: "",//0912345678
        //     birthday: "",//2000-01-01
        //     address_city: "",//台北市
        //     permission: "一般會員",
        //     gender: ""//male
        // };

        // const responseMessage = userInfo.data;

        // console.log("responseMessage: "+responseMessage);

        return {
            "id": userInfo.data.message.id ? userInfo.data.message.id : "",
            "nickName": userInfo.data.message.nick_name ? userInfo.data.message.nick_name : "",
            "email": userInfo.data.message.email ? userInfo.data.message.email : "",
            "profilePicture": userInfo.data.message.profile_picture ? userInfo.data.message.profile_picture : "",
            "phone": userInfo.data.message.phone ? userInfo.data.message.phone : "",
            "birthday": userInfo.data.message.birthday ? dayjs(userInfo.data.message.birthday, 'YYYY-MM-DD') : dayjs(dayjs(), 'YYYY-MM-DD'),
            // "birthday": userInfo.data.message.birthday ? dayjs(userInfo.data.message.birthday).format('YYYY-MM-DD') : "",
            "addressCity": userInfo.data.message.address_city ? userInfo.data.message.address_city : "",
            "permission": userInfo.data.message.permission ? userInfo.data.message.permission : "",
            "gender": userInfo.data.message.gender ? userInfo.data.message.gender : "",
            "township" : userInfo.data.message.township ? userInfo.data.message.township : "",
           
        }

    } catch (error) {
        console.log("getUserInfo-Error: ", error);
        // throw new Error('token expire');
        //Log errors
    }
}

export const updateUserInfo = async (payload: object) => {
    return await post(`${API_URL}/api/user`, payload, getAuthHeader())
}


export const enableTour = async (payload: object) => {
    return await post(`${API_URL}/api/user_tour`, payload, getAuthHeader())
}


