import React, { useEffect, useState, useContext, useRef } from 'react';
import AuthContext from '../../context/authContext';
import { Navigate } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLine } from '@fortawesome/free-brands-svg-icons'
import { LINE_LOGIN_URI, LINE_LOGIN_CLIENT_ID, LINE_REDIRECT_URL } from '../../configuration'
import { YJ_ACCESS_TOKEN, USER_INFO_KEY } from '../../configuration'
import MainLayout from '../../layouts/ManLayout';
import { tokenExpire } from '../../apis/utils';
import liff from '@line/liff'; // 追加
import { IS_MOBILE_LOGIN } from '../../configuration';
import { useQuery, QueryCache } from 'react-query';
import { AuthInfoData } from '../../types/CustomType';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import UserService from '../../apis/UserService';

interface TokenPayload {
  sub: string;  //TODO
  name: string;
  picture: string;
}

const LoginPage = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [lineToken, setLineToken] = useState<string>('');

  const handleLogin = () => {
    // const lineAuthUrl = LINE_LOGIN_URI;
    // const clientId = LINE_LOGIN_CLIENT_ID;
    // const redirectUri = LINE_REDIRECT_URL;
    // window.location.href = `${lineAuthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=STATE&scope=profile%20openid%20email`;

    //2000457318-ObG6q2PX (測試)
    //2000457318-rWmXJdL2(正式)

    // async function initializeLiff() {
    //   try {
    //     await liff.init({ liffId: '2000457318-rWmXJdL2' });

    //     if (!liff.isLoggedIn()) {
    //       // console.log("尚未登入");
    //       liff.login();
    //       localStorage.setItem(IS_MOBILE_LOGIN, `true`)
    //       authCtx.setMobileLogin(true);


    //     } else {
    //       // console.log("已經登入");
    //       liff_login();
    //     }
    //   } catch (error) {
    //     console.error('LIFF initialization failed', error);
    //   }
    // }

    // initializeLiff();
  };

  // useEffect(() => {
  //   if (lineToken != "" && lineToken != null) {
  //     // console.log("[useEffect] profile.lineIDToken = "+lineToken);
  //     getYjTokenAndStatusAction();
  //   }

  // }, [lineToken]);

  // const { refetch: getYjTokenAndStatusAction } = useQuery<AuthInfoData, Error>(
  //   `queryKey${lineToken}`,
  //   async () => await UserService.getYjTokenAndStatus(lineToken),
  //   {
  //     enabled: !!lineToken,

  //     onSuccess: (res) => {
  //       if (res) {
          
  //         const loginInfo = { 'token': res.token, 'isNewUser': res.is_new_user };
  //         if (loginInfo?.token) {
  //           const payload = jwt_decode(loginInfo.token) as TokenPayload;
  //           localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
  //           authCtx.updateUserInfo();
  //           console.log("[LoginPage] getYjTokenAndStatusAction");
  //            navigate('/mobile');
  //         }

  //       }

  //     }
  //   }
  // );

  const liff_login = async () => {
    try {
      // const profile = await getProfile(`${code}`);

      const profile = {
        "lineId": (await liff.getProfile()).userId,
        "lineIDToken": liff.getIDToken() ?? "",
      }

      // setLineToken(profile.lineIDToken);
      setLineToken(profile.lineIDToken);


    } catch (error) {
      console.error(error);
    }
  }
  //&& !tokenExpire()
  if (authCtx.isLogin ) {
    // navigate('/');
    return (<Navigate to="/" />)
  } else {
    return (
      <MainLayout>
        <div className='h-full flex justify-center items-center'>
          <div className="bg-white p-16 rounded shadow min-w-[40vw] mt-8">
            <h1 className="text-2xl font-bold mb-4">登入</h1>
            <Button
              type="primary"
              block
              onClick={()=>{console.log('login')}}
              className='  text-white'
              icon={<FontAwesomeIcon icon={faLine} style={{ color: '#01b601' }} className="w-4 h-4 mr-2 bg-white rounded-lg" />}
            >
              使用 Line 登入
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }
};
export default LoginPage;
