import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../context/authContext';
import { USER_INFO_KEY, YJ_ACCESS_TOKEN,IS_USER_LOGIN } from '../../configuration';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Button, Select, DatePicker, Checkbox, Modal, Avatar, Typography } from 'antd';
import dayjs from 'dayjs';
// import moment from "moment";
import { UserOutlined } from '@ant-design/icons';
import MainLayout from '../../layouts/ManLayout';
import { getUserInfo, updateUserInfo } from '../../apis/member';
import { IUserInfo } from '../../types/Member';
import { UserInfoData } from '../../types/CustomType';
import MobileLayout from '../../layouts/MobileLayout';
import moment from 'moment';
import 'moment/locale/zh-tw';

moment.locale('zh-tw');

const { Title, Paragraph } = Typography;

interface IFormItem {
  nick_name: string;
  phone: string;
}

const ProfilePage = () => {


  const onFinish = async (values: any) => {
    console.log("onFinish:", values);
    try {
      const updatePayload = {
        "nick_name": values.nick_name,
        "email": values.email,
        // "profile_picture": 'https://profile.line-scdn.net/0h5jqJp36_akVMM3_Ogx8VEnB2ZCg7HWwNNFYnJDtkN3M0BH4RcQckdz0xM3IyCnhDeFAsd2s2MiE2',
        "profile_picture": values.profile_picture,
        "phone": values.phone,
        "gender": values.gender,
        "birthday": values.birthday.format('YYYY-MM-DD'),
        "address_city": values.address_city,
      }


      //console.log("updatePayload="+values.nickName);

      //TODO post data
      await updateUserInfo(updatePayload)
        .then((res) => {
          //console.log("updateUserInfo-res-1: ", res);
          mode == 'register' ?
            setModalContent(<p>永靖歡迎您的加入，註冊成功! 頁面將回到首頁 </p>)
            : setModalContent(<p>會員資訊，已更新成功!</p>);
          authCtx.updateUserInfo();
        })
        .catch((error) => {
          const errorRsp = JSON.parse(error.response.data);
          if (errorRsp) {
            const formItem = {
              nick_name: '暱稱',
              phone: '電話',
            }
            //console.log("errorRsp:", errorRsp.message);
            const errorElements: React.ReactNode[] = [];
            Object.keys(errorRsp.message).forEach((key) => {
              const errorMessage = (
                <p key={key}>
                  {formItem[key as keyof IFormItem]} : {errorRsp.message[key]}
                </p>
              );
              errorElements.push(errorMessage);
            })
            setModalContent(
              <>
                <p>會員資訊更新失敗</p>
                {errorElements}
              </>
            );
          } else {
            setModalContent(
              <>
                <p>會員資訊更新失敗，請稍後再進行更新。</p>
              </>
            );
          }
        });


      setIsModalOpen(true);
    } catch (error) {
      console.log('onFinish-error:', error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  const memberRegistration = () => {
    return (<>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Typography>
          <Title level={2}>會員註冊權益</Title>
          <Paragraph>
            <pre className=' text-left'>
              加入我們的會員計畫，您將享受以下專屬權益加入我們的會員<br />
              計畫，您將享受以下專屬權益:<br />
              1.享受專屬優惠折扣<br />
              2.獲得精選活動優先報名權<br />
              3.專屬會員禮遇及驚喜禮品<br />
              4.參與會員專屬活動<br />
              5快速線上預約及服務<br />
            </pre>

          </Paragraph>
        </Typography>
      </Form.Item>
      <Form.Item name="agree" wrapperCol={{ span: 24 }} valuePropName="checked">
        <Checkbox >我已詳閱並同意以上所有條款</Checkbox>
      </Form.Item>
    </>
    )
  }

  const config = {
    rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
  };

  const getUserData = (async (isNewUser: boolean, localUserInfo: string) => {
    try {
      // let userInfo;
      // //若是新進成員的話
      // if (isNewUser) {
      //   const userData = JSON.parse(localUserInfo);
      //   userInfo = {
      //     "id": userData.userId,
      //     "nickName": userData.displayName,
      //     "email": "",
      //     "profilePicture": userData.profilePicture,
      //     "phone": "",
      //     "birthday": "",// ??? dayjs(),
      //     "addressCity": "",
      //     "permission": "",
      //     "gender": ""
      //   }
      // } 
      // //若是已存在的成員的話
      // else {
      //   console.log("getUserData-isNewUser:", isNewUser);
      //   userInfo = await getUserInfo();
      // }
      // console.log('userInfouserInfouserInfo:', userInfo);


      // if (userInfo) {
      //   setProfile(userInfo);
      //   form.setFieldsValue(userInfo);
        
      // }
      //TODO else

    } catch (error) {
      console.error('getUserData', error);
    }
  })

  const handleUploadAvatar = (() => {
    // TODO
    // if (!avatarFile) {
    //   message.error('請先選擇要上傳的照片');
    //   return;
    // }
    // setLoading(true);
    // const formData = new FormData();
    // formData.append('avatar', avatarFile);

  })

  const getAvatar = (() => {

   

    if (profile?.profile_picture) {
      return <Avatar src={profile?.profile_picture} size={80} alt="user_avatar" onClick={handleUploadAvatar} />;
    } else {
      return <Avatar
        // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
        size={80}
        icon={<UserOutlined />}
        className='w-full' />
    }
  })

  //編輯
  const handleOk = () => {
    // if (mode == 'register') {
    //   setIsModalOpen(false);
    //   navigate('/');
    // } else {
    //   setIsModalOpen(false);
    // }
    navigate('/mobile');
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const validatePhone = (_: any, value: string) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('請輸入有效的電話號碼');
  };

  useEffect(() => {
    // console.log('[ProfileMobilePage] profile authCtx.userInfo :', authCtx.userInfo);
   
    // const localUserInfo = localStorage.getItem(USER_INFO_KEY);
    // if (localUserInfo) {
    //   if (JSON.parse(localUserInfo).isLogin == false && localStorage.getItem(YJ_ACCESS_TOKEN)) {
    //     setIsNewUser(true);
    //   } else {
    //     setIsNewUser(false);
    //   }
    //   getUserData(isNewUser, localUserInfo);
    // }

    const localUserInfo = localStorage.getItem(USER_INFO_KEY);
    const userLoginLocalstorage = localStorage.getItem(IS_USER_LOGIN);
    if (userLoginLocalstorage && localUserInfo) {
      
      // const isLogin =  userLoginLocalstorage ? JSON.parse(userLoginLocalstorage) : false;

      // if (isLogin == false && localStorage.getItem(YJ_ACCESS_TOKEN)) {
      //   setIsNewUser(true);
      // } else {
      //   setIsNewUser(false);
      // }
      const userData = JSON.parse(localUserInfo);
      
      userData["birthday"] = moment(userData["birthday"]);
      
      // console.log("userData = "+userData["birthday"]);
      setProfile(userData);
      form.setFieldsValue(userData);
      //getUserData(isNewUser, localUserInfo);
    }
   

  }, []);

  const cities = [{ value: '臺北市', label: '臺北市' }
    , { value: '新北市', label: '新北市' }
    , { value: '桃園市', label: '桃園市' }
    , { value: '臺中市', label: '臺中市' }
    , { value: '臺南市', label: '臺南市' }
    , { value: '高雄市', label: '高雄市' }
    , { value: '基隆市', label: '基隆市' }
    , { value: '新竹市', label: '新竹市' }
    , { value: '嘉義市', label: '嘉義市' }
    , { value: '新竹縣', label: '新竹縣' }
    , { value: '苗栗縣', label: '苗栗縣' }
    , { value: '彰化縣', label: '彰化縣' }
    , { value: '南投縣', label: '南投縣' }
    , { value: '雲林縣', label: '雲林縣' }
    , { value: '嘉義縣', label: '嘉義縣' }
    , { value: '屏東縣', label: '屏東縣' }
    , { value: '宜蘭縣', label: '宜蘭縣' }
    , { value: '花蓮縣', label: '花蓮縣' }
    , { value: '臺東縣', label: '臺東縣' }
    , { value: '澎湖縣', label: '澎湖縣' }
    , { value: '金門縣', label: '金門縣' }
    , { value: '連江縣', label: '連江縣' }];

  const authCtx = useContext(AuthContext);
  // console.log("home-authCtx.userInfo-3:", authCtx);
  const navigate = useNavigate();
  //const [profile, setProfile] = useState<IUserInfo>();
  const [profile, setProfile] = useState<UserInfoData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>();
  const [form] = Form.useForm();
  const location = useLocation().pathname;
  const mode = location.substring(location.lastIndexOf('/') + 1);
  const [avatarFile, setAvatarFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  // const localUserInfo = localStorage.getItem(USER_INFO_KEY);
  // const isNewUser = checkNewUser(localUserInfo);
  type LayoutType = Parameters<typeof Form>[0]['layout'];
  const [form1] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

 


  return (
    <MobileLayout>
      <Modal title="系統訊息" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {modalContent}
      </Modal>
      <div className="bg-white w-full rounded shadow flex-col justify-center p-4 ">
        <h1 className="text-2xl font-bold mb-4"> 永靖會員資料編輯 </h1>
        <div className='w-full flex justify-center'>
        <Form
          form={form}
          initialValues={profile}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ maxWidth: formLayout === 'inline' ? 'none' : 600 }}
        >
          {/* 欄位一 : 大頭貼*/}
          <Form.Item wrapperCol={{ span: 24 }}>
                {getAvatar()}
          </Form.Item>
          {/* 欄位二 : 暱稱*/}
          <Form.Item label="暱稱" name={['nick_name']} rules={[{ required: true, message: '請輸入暱稱!' }]}>
            <Input placeholder="請輸入暱稱" />
          </Form.Item>
          {/* 欄位三 : 電子信箱*/}
          <Form.Item label="電子信箱" name={['email']} rules={[{ type: 'email', required: true, message: '確認是否符合電子信箱格式' }]}>
            <Input placeholder="請輸入電子信箱" />
          </Form.Item>
          {/* 欄位四 : 電話*/}
          <Form.Item label="電話" name="phone" rules={[{ required: true, message: '請輸入您的聯繫電話', },{ validator: validatePhone}]}>
            <Input placeholder="請輸入電話號碼" />
          </Form.Item>
          {/* 欄位五 : 性別*/}
          <Form.Item
                name="gender"
                label="性別"
                rules={[{ required: true, message: '請選擇您的性別!' }]}
              >
                <Select placeholder="請選擇您的性別">
                  <Select.Option value="male">男性</Select.Option>
                  <Select.Option value="female">女性</Select.Option>
                  <Select.Option value="other">不公開</Select.Option>
                </Select>
              </Form.Item>
        
          {/* 欄位六 : 生日 {...config}*/}
          <Form.Item name="birthday" label="生日" >  
              <DatePicker className='w-full' />
          </Form.Item>
          {/* 欄位七 : 居住縣市*/}
          <Form.Item
                label="居住縣市"
                name="address_city"
                rules={[{ required: true, message: '請選擇您所居住的縣市' }]}
              >
                <Select placeholder="請選擇您的居住縣市" options={cities}>
                </Select>
          </Form.Item>
          {/* 欄位八 : 確認修改*/}
          <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" className=' w-4/5'>
                {/* {mode == 'register' ? '確認註冊' : '確認修改'} */}
                確認修改
              </Button>
          </Form.Item>
        </Form>
        </div>
        
      </div>
    </MobileLayout>
  );
};
export default ProfilePage;
