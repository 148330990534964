import React from 'react';
import MainLayout from '../../layouts/ManLayout';

const HomePage = () => {
  return (
    <MainLayout>
      <h1 className="text-2xl font-bold mb-4"> 永靖 </h1>
    </MainLayout>
  );
};
export default HomePage;
