import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;
import Header from './Header';
import './style.css';

interface MobileLayoutProps {
  children: React.ReactNode;
}

interface MobileLayoutProps {
  children: React.ReactNode;
}

const MobileLayout: React.FC<MobileLayoutProps> = ({ children }) => {

  return (
    <Layout className='mainBlock layout w-screen justify-center items-center bg-black' >
      <Layout className='layout w-full max-w-[550px] min-w-[375px] sm:max-h-[844px] sm:min-h-[667px]  justify-center items-center bg-slate-100 '>
        <Header />
        <Content className=' text-center w-full flex-grow flex flex-col relative'>
          {children}
        </Content>
      </Layout>
    </Layout>
  )


};

export default MobileLayout;
