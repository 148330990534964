import { post, get, getAuthHeader } from './base';
import { API_URL } from '../configuration';

//取得預設標籤
const findDefault = async () =>{
    const response = await get(`${API_URL}/api/tag?query_type=public`);
    return response.data;
}

const TagService = {
    findDefault,
}

export default TagService;
