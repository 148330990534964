import React, { useEffect, useState, useContext, useRef } from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';
//import Attraction from '../types/Attraction';
import Tag from '../types/Tag';
import AttractionService from '../apis/AttractionService';
//import { AttractionType } from '../types/ItineraryType';
import { AttractionData,SingleMapLinkType } from '../types/CustomType';
import { ProcessCurrentUserData } from '../lib/MapLib';
import {MapInfoContext} from '../context/mapInfoContext';

interface Props {
    data: AttractionData[];
    customized?: boolean;
}

interface Location {
    lat: string;
    lng: string;
}


const Attractions: React.FC<Props> = ({ data, customized }) => {

    const [tags, setTags] = useState<Tag[]>([]);
    

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, destination_address: string) => {
        event.preventDefault();
        // Handle your logic here
        const id = navigator.geolocation.watchPosition(function (position) {
            // alert("i'm tracking you!" + position.coords.latitude);
            
            const str_origin = "origin=" + position.coords.latitude + ","+ position.coords.longitude;
            const str_dest = "destination=" + destination_address;
            const parameters = str_origin + "&" + str_dest + "&" + "sensor=false";

            // window.location.href="https://www.google.com/maps/dir/?api=1&"+parameters;
            window.open("https://www.google.com/maps/dir/?api=1&"+parameters);
            //console.log("parameters=" + parameters);
            navigator.geolocation.clearWatch(id);
            // return "https://www.google.com/maps/dir/?api=1&"+parameters;
        },
        function (error) {
            if (error.code == error.PERMISSION_DENIED){
                // alert("you denied me :-(");
                // I suspect this is actually unnecessary since we were denied.
                navigator.geolocation.clearWatch(id);
            }
        });

        //console.log(id);


        //起點
        const str_origin = "origin=" + 0 + ","+ 0;
        const str_dest = "destination=" + destination_address;
        const parameters = str_origin + "&" + str_dest + "&" + "sensor=false";
        console.log("https://www.google.com/maps/dir/?api=1&"+parameters);

        return "https://www.google.com/maps/dir/?api=1&"+parameters;
    };


    return (
        <div>
            {customized == true &&
                <div className='text-base pb-4 py-1'>
                    <span className='text-slate-700 font-semibold'>想再做一次小測驗?</span>
                    <Link to={'/mobile/quiz'} className='text-primary font-semibold pl-2' >
                        GO
                        <FontAwesomeIcon icon={faLongArrowAltRight} className='pl-2 ' />
                    </Link>
                </div>
            }
            {data.map((attraction, index) => (
                <div key={index}>
                    {/* 1   2st = lex flex-col py-6*/}
                    <div className='flex flex-col '>
                        {/* <div className=' text-primary text-2xl py-2 w-full font-bold'>經典路線(名稱)景點介紹...</div> */}
                        <div className=' h-[130px] w-[333px] flex overflow-auto'>
                            {
                                attraction.images.map((value, index) => (
                                    <div className='px-1' key={index}>
                                        <Image
                                            width={196}
                                            height={127}
                                            className='object-cover'
                                            src={value.image_slug}
                                        />
                                    </div>
                                ))
                            }

                        </div>
                        <div className=' text-secondary text-xl py-2 font-bold  '>{attraction.title}</div>
                        <div className=' text-secondary h-[108px] w-full overflow-auto'>{attraction.description}</div>
                        <div className=' flex py-2'>
                            <div className=' w-1/2 border-r-2 border-secondary'>
                                <div className=' text-primary text-xs pd-1 font-bold'>營業時間：{attraction.open_time}</div>
                                <div className=' text-primary text-xs pd-1 font-bold'>建議停留時間：{attraction.stay_time}</div>
                            </div>
                            <div className=' w-1/2 flex items-center '>
                                <FontAwesomeIcon icon={faLocationDot} style={{ color: "#e04c4c" }} size="lg" className='px-2' />
                                <div className=' text-xs'><a href="" onClick={(e) => handleClick(e, attraction.address)} target="_blank" rel="noreferrer">google</a></div>
                            </div>
                        </div>

                        <div className='w-full flex justify-end text-tertiary'>
                            {
                                attraction.tags.map((value, index) => (<div className='px-1' key={index}>#{value.name}</div>))
                            }
                        </div>
                    </div>
                </div>
            ))}


        </div>
    );
};

export default Attractions;
