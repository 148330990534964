import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/authContext';
import { Button, Dropdown, Layout, Avatar, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useLocation, Link,useParams } from 'react-router-dom';
import { USER_INFO_KEY } from '../../../configuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLine } from '@fortawesome/free-brands-svg-icons'
import { faQrcode, faHomeLgAlt,faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { LINE_LOGIN_URI, LINE_LOGIN_CLIENT_ID, LINE_REDIRECT_URL, IS_MOBILE_LOGIN,LINE_CHANNEL_ID,LINE_REDIRECT_URI,COOKIE_NAME,LINE_CHANNEL_SECRET,COOKIE_GET_LINETOKEN_NAME,COOKIE_LOGUT_NAME} from '../../../configuration'
import { hover } from '@testing-library/user-event/dist/hover';
import QRScannerV2 from '../../../components/QRScannerV2';
import { UserInfoContext } from '../../../context/userInfoContext';
// 登入 line 用
import liff from '@line/liff'; 

import { useQuery, QueryCache } from 'react-query';
import { AuthInfoData } from '../../../types/CustomType';
import jwt_decode from 'jwt-decode';
import { useNavigate} from 'react-router-dom';
import UserService from '../../../apis/UserService';
import { YJ_ACCESS_TOKEN,ORIGINAL_URL} from '../../../configuration'
import './style.css'; // 客製化 CSS
const AntHeader = Layout.Header;


interface TokenPayload {
  sub: string;  //TODO
  name: string;
  picture: string;
}

export interface ProfileData {
  nickName: string;
  profilePicture: string;
}

type RouteParams = {
  attractionName : string | undefined;
};

const Header: React.FC = (props) => {


  const [attrActionName, setAttractionName] = useState<any>();


  const {attractionName} = useParams<RouteParams>();


  const guestItems: MenuProps['items'] = [//訪客
    {
      key: '1',
      label: (
        // <Link to={'/login'} className='w-full flex justify-center items-center my-2'>
        //   <span><UserOutlined /></span>
        //   <span className='w-full pl-2 text-stone-600'>登入</span>
        // </Link>
        <div className='px-2 w-full' onClick={() => setIsLineLoginOpen(true)}>
          登入
        </div>
      ),
    }
  ]

  const memberItems: MenuProps['items'] = [//會員
    {
      key: '1',
      label: (
        <Link to={'/mobile/profile/main'} className='w-full flex justify-center items-center mt-1' onClick={() => { console.log("編輯頁面") }}>
          <span className='w-full pl-2 text-stone-600'>檢視個人資訊</span>
        </Link>
      )
    },
    {
      key: '2',
      label: (
        <Link to={'/mobile'} className='w-full flex justify-center items-center mt-1' onClick={() => { handleLogout() }}>
          <span className='w-full pl-2 text-stone-600'>登出</span>
        </Link>
      )
    }
  ]
  const {
    ...otherProps
  } = props;
  const authCtx = useContext(AuthContext);
  // const userInfo = authCtx;
  // const navigate = useNavigate();
  // const userData = JSON.parse(localStorage.getItem(USER_INFO_KEY)|| '{}');
  // const userData = userInfo;
  // const userName = userInfo.mail.split('@')[0];
  

  const [isLineLoginOpen, setIsLineLoginOpen] = useState(false);
  const [isQrModalOpen, setQrModalOpen] = useState(false);
 

  const navigate = useNavigate();
  

  const goBack = () => {
    //navigate(-1); // -1 means go back one step in the history

    if(localStorage.getItem(ORIGINAL_URL) == "/mobile/profile/main" || localStorage.getItem(ORIGINAL_URL) == "/mobile/profile/collection"  || localStorage.getItem(ORIGINAL_URL) == "/mobile/quiz") { // 個人資訊的會員資料與集點卡頁簽都返回至 /mobile
      // console.log("[Header] 導航回:/mobile")
      // ORIGINAL_URL 更改為 /mobile
      
      navigate("/mobile");
      localStorage.setItem(ORIGINAL_URL,"/mobile");
    } else if (localStorage.getItem(ORIGINAL_URL)?.includes("/mobile/profile/collection/")) { // 集章卡細項路徑
      navigate("/mobile/profile/collection");
      localStorage.setItem(ORIGINAL_URL,"/mobile/profile/collection")
    } else {
      navigate(-1);
    }
    
  };

  const goHome = () => {
    
    if(localStorage.getItem(ORIGINAL_URL) == "/mobile" ) {
      window.location.reload();
    } else {
      localStorage.setItem(ORIGINAL_URL,"/mobile")
      navigate("/mobile");
    }

  };

  //大頭貼連結
  const [avatarPath, setAvatarPath] = useState<string>('');

  useEffect(() => {

    if (authCtx.isMobileLogin) {
      const storageUserInfo = localStorage.getItem(USER_INFO_KEY);
      const userInfo = storageUserInfo ? JSON.parse(storageUserInfo) : null;

      if (userInfo?.profile_picture) {
        setAvatarPath(userInfo?.profile_picture);
      }

    } else {
      console.log("未登入");
    }

  }, [authCtx.isMobileLogin]);

  const [lineToken, setLineToken] = useState<string>('');

  // useEffect(() => {

  //   console.log("[Header] [lineToken] :"+lineToken);

  //   if (lineToken != "" && lineToken != null) {
  //     console.log("[Header] profile.lineIDToken = "+lineToken);
  //     getYjTokenAndStatusAction();
  //   }

  // }, [lineToken]);

  const [loggedIn, setLoggedIn] = useState(false);
  const [lsGetToken, setLsGetToken] = useState(false);

  useEffect(() => {

    
   
    // const isLoggedIn = document.cookie.includes(`${COOKIE_NAME}`);
    // const isGetToken = document.cookie.includes(`${COOKIE_GET_LINETOKEN_NAME}`);
    // console.log("[Header] 檢查 loggedIn :"+isLoggedIn+" 是否要拿 Line token:"+isGetToken);
    // setLoggedIn(isLoggedIn);
    // setLsGetToken(isGetToken);

    // // 如果没有登录状态，则自动发起登录
    // if (!isGetToken) {
    //   initiateLogin();
    // } else {
    //   const params = new URLSearchParams(window.location.search);
    //   const state = params.get('state');

    //   console.log("state = "+state);

    //   if(!isLoggedIn) {
        
    //     if(state == "STATE") {
    //       extractLineToken();
    //     } else {
    //       initiateLogin();
    //     }

    //   } else {
    //     authCtx.updateUserInfo();
    //   }

    // localStorage.setItem(ORIGINAL_URL, location.pathname)
    // if(authCtx.isLogin){
    //   const original_url = localStorage.getItem(ORIGINAL_URL);
    //   authCtx.updateUserInfo();
    //   setAttractionName(attractionName);
    //   if(original_url != '/mobile' && original_url != undefined) {
    //     navigate(original_url);
    //   }
    // } else {
    //   // 觸發 Line 登入
   
    // }
    
  }, [location.pathname]);

  const initiateLogin = () => {
    // console.log("[Header] initiateLogin");
    // document.cookie = `${COOKIE_LOGUT_NAME}=false; path=/`;
    // document.cookie = `${COOKIE_GET_LINETOKEN_NAME}=true; path=/`;
    // 觸發 Line 登入
    localStorage.clear();
    localStorage.setItem(ORIGINAL_URL, "/mobile");
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${generateRandomString(5)}&scope=openid%20profile%20email`;
  };

  const generateRandomString = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  // 登出
  const handleLogout = () => {
   
    setLoggedIn(false);
    authCtx.setMobileLogin(false);
    authCtx.logout();
    navigate("/mobile");

    
    // 自动发起登录
    // initiateLogin();
  };

  // useEffect(() => {
  //   console.log("按了登出 :"+ authCtx.isMobileLogin);
  // }, [authCtx.isMobileLogin]);

  

  const extractLineToken = () => {
    // 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      // 发送请求以获取 LINE Token
      fetch('https://api.line.me/oauth2/v2.1/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code,
          redirect_uri: LINE_REDIRECT_URI,
          client_id: LINE_CHANNEL_ID,
          client_secret: LINE_CHANNEL_SECRET, // 请替换为实际的 Channel Secret
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // const accessToken = data.access_token;

          // console.log("line data ="+data);
          

          // const userId = data.scope;
          // const displayName = data.displayName;
          // const userEmail = data.email; // 获取邮箱地址
          // console.log('User ID:', userId);
          // console.log('Display Name:', displayName);
          // console.log('User Email:', userEmail);

          // const token = data.access_token;
          const idToken = data.id_token;
          // console.log('token:', token);
          // console.log('idToken:', idToken);

          // 在 Cookie 中存储登录状态
          document.cookie = `${COOKIE_NAME}=true; path=/`;
          setLineToken(idToken)
          // 更新登录状态
          setLoggedIn(true);
        })
        .catch((error) => {
          console.error('Error fetching LINE Token:', error);
        });
    }
  };

  // useEffect(() => {
  //   if(lsGetToken) {
  //     extractLineToken();
  //   }
  // }, [lsGetToken]);


  
  // const { refetch: getYjTokenAndStatusAction } = useQuery<AuthInfoData, Error>(
  //   `queryKey${lineToken}`,
  //   async () => await UserService.getYjTokenAndStatus(lineToken),
  //   {
  //     enabled: !!lineToken,

  //     onSuccess: (res) => {
  //       if (res) {
          
  //         const loginInfo = { 'token': res.token, 'isNewUser': res.is_new_user };
  //         if (loginInfo?.token) {
  //           const payload = jwt_decode(loginInfo.token) as TokenPayload;
  //           localStorage.setItem(YJ_ACCESS_TOKEN, loginInfo.token);
           
  //           authCtx.updateUserInfo();
            
  //           //  navigate('/mobile');
  //            //跳轉頁面
  //           if(localStorage.getItem(ORIGINAL_URL) == "/mobile/profile/main") {
  //             navigate("/mobile/profile/main");
  //           }
  //           else if(localStorage.getItem(ORIGINAL_URL) == "/mobile/profile/collection") {
  //             navigate("/mobile/profile/collection");
  //           }
  //           else if(localStorage.getItem(ORIGINAL_URL) == "/mobile/quiz") {
  //             navigate("/mobile/quiz");
  //           }
  //           else {
  //             navigate("/mobile");
  //           }
  //         }

  //       }

  //     },

  //     onError: (err: any) => {
  //       document.cookie = `${COOKIE_LOGUT_NAME}=false; path=/`;
  //       document.cookie = `${COOKIE_GET_LINETOKEN_NAME}=false; path=/`;
  //       initiateLogin();
  //     }
  //   }
  // );

  const userContext = useContext(UserInfoContext);


  // const handleLogin = () => {

  //   async function initializeLiff() {
  //     try {
  //       await liff.init({ liffId: '2000457318-rWmXJdL2' });

  //       if (!liff.isLoggedIn()) {
  //         // console.log("尚未登入");
  //         liff.login();
  //         localStorage.setItem(IS_MOBILE_LOGIN, `true`)
  //         authCtx.setMobileLogin(true);

          
  //       } else {
  //         // console.log("已經登入");
  //         liff_login();
  //       }
  //     } catch (error) {
  //       console.error('LIFF initialization failed', error);
  //     }
  //   }

  //   initializeLiff();
  // };

  // const liff_login = async () => {
  //   try {
      
  //     const profile = {
  //       "lineId": (await liff.getProfile()).userId,
  //       "lineIDToken": liff.getIDToken() ?? "",
  //     }

  //     // setLineToken(profile.lineIDToken);
  //     setLineToken(profile.lineIDToken);


  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  function refreshPage() {
    setTimeout(() => {
      window.location.reload();
    }, 500);
    console.log('page to reload')
  }

  const checkInProfilePage = () => {
    return useLocation().pathname.startsWith('/mobile/profile/');
  }


  return (

    <AntHeader style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '100%',
      height: '50px'
    }} className=' bg-primary flex justify-between items-center mx-auto text-base text-white px-3'>
      <Modal open={isLineLoginOpen} footer={null} onCancel={() => setIsLineLoginOpen(false)} centered >
        <div className=' flex-col justify-center items-center '>
          <h2 className='text-center text-primary'>登入</h2>
          <div className='text-center'>
            <Button
              shape="round"
              className=' text-white bg-line-color hover:bg-primary '
              onClick={()=>{initiateLogin()}}
              icon={
                <FontAwesomeIcon
                  icon={faLine}
                  className="w-4 h-4 mr-2 rounded-lg " />
              }
            >
              用 Line 登入
            </Button>
          </div>
        </div>
        <div className=' flex-col justify-center items-center '>
          <h2 className='text-center text-primary'>沒有帳號?</h2>
          <div className='text-center'>
            <Button
              shape="round"
              className=' text-white bg-line-color hover:bg-primary '
              onClick={()=>{console.log('login')}}
              icon={
                <FontAwesomeIcon
                  icon={faLine}
                  className="w-4 h-4 mr-2 rounded-lg" />
              }
            >
              用 Line 註冊
            </Button>
          </div>
        </div>
      </Modal>
      <QRScannerV2
        isVisible={isQrModalOpen}
        title="請掃描 QR Code 圖片"
        onClose={() => setQrModalOpen(false)}
      >
      </QRScannerV2>
      {/* {
        checkInProfilePage() && <div className=' w-14' />
        {localStorage.getItem(ORIGINAL_URL) || "/mobile"}
      } */}

      {
          (location.pathname != "/mobile" && (!attrActionName))?
          <div className="custom-width">

            <div className='flex items-left'>
              <Button className="transparent-button" onClick={()=>{goBack();}} >
                {attrActionName}
                <FontAwesomeIcon icon={faArrowLeftLong} className='flex justify-left item-left inside-button' />        
              </Button> 
            </div>
          </div>
          
          :  <div className="custom-width" />
      }

      

      <div className=''>
        {/* <Link to={'/mobile'} onClick={refreshPage} className=' text-white'>
          <FontAwesomeIcon icon={faHomeLgAlt} className='flex justify-center item-center' />
        </Link> */}
        <Button className="transparent-button" onClick={()=>{goHome();}} >
          <FontAwesomeIcon icon={faHomeLgAlt} className='flex justify-center item-center inside-button' />
        </Button>

      </div>
      <div className='flex items-center'>
        {
          // useLocation().pathname.startsWith('/mobile') &&
          (!location.pathname.includes("/mobile/quiz")) ?
          <Button className="transparent-button pr-3" onClick={()=>{setQrModalOpen(true);}} >
            <FontAwesomeIcon icon={faQrcode} className='flex justify-center item-center inside-button' />
          </Button> :
          <div className="custom-width2"/>

        }
        <Dropdown menu={{ items: (authCtx.isMobileLogin) ? memberItems : guestItems }} placement="bottomLeft" arrow >
          <Button shape="circle" type='text' className='flex justify-center items-center inside-button'>

            {
              // authCtx.isLogin ? <Avatar src={avatarPath} alt="Icon" /> : <UserOutlined className='text-white' />

              (authCtx.isMobileLogin ) ? <Avatar src={authCtx.userInfo.profile_picture} alt="Icon" /> : <UserOutlined className='text-white' />
            }

          </Button>
        </Dropdown>
      </div>
    </AntHeader>
  );
};

export default Header;
